import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Label, Input, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from "react-redux";

import * as actions from '../../store/PaymentBulk/actions';
import * as actionsAllocation from '../../store/Allocation/actions';
import Backdrop from '../Common/Backdrop';
import * as columnsPaymentBulk from '../../definitions/columns/paymentBulk';
import * as editFormControls from "../../helpers/editFormControls";
import * as formatUtils from '../../helpers/formatUtils';
import * as selectRowUtils from '../../helpers/selectRowUtils';

import classes from './Pages.module.css';

class SelectPayment extends Component {

    constructor(props) {
        super(props);
        this.filterBySupplierName = this.filterBySupplierName.bind(this);
        this.filterBySupplierRegNo = this.filterBySupplierRegNo.bind(this);
        this.filterByAmount = this.filterByAmount.bind(this);
        this.state = {
            selectedRows: [],
            totalAmount: null
        };
    }

    componentDidMount() {
        this.props.onGetUnallocatedPayments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.totals !== this.props.totals) {
            if (this.props.totals && this.props.totals.amount) {
                this.setState({
                    totalAmount: this.props.totals.amount
                });
            } else {
                this.setState({
                    totalAmount: null
                });
            }
        }
    }

    filterBySupplierName(value) {
        this.supplierNameFilter(value);
    }

    filterBySupplierRegNo(value) {
        this.supplierRegNoFilter(value);
    }

    filterByAmount(value) {
        this.amountFilter(value);
    }

    onCreateAllocation = () => {
        const paymentBulks = [];
        this.state.selectedRows.map(selectedRow => {
            paymentBulks.push({
                id: selectedRow
            })
        })
        const allocation = {
            bankStatementRecordId: this.props.allocationRecord.id,
            paymentBulks: paymentBulks
        }
        this.props.onCreateAllocation(allocation);
        this.props.onClose();
    }

    onCalculateTotals = (selectedRows) => {
        if (!this.props.allocationRecord) {
            return;
        }
        const filteredInvoices = this.props.paymentBulks.filter(payment => selectedRows.includes(payment.id));
        let totalAmount = 0;
        let hasDifferentCurrencies = false;
        filteredInvoices.map(invoice => {
            if (invoice.currencyCode === this.props.allocationRecord.currencyCode) {
                totalAmount += invoice.unallocatedAmount;
            } else {
                hasDifferentCurrencies = true;
            }
        });
        if (hasDifferentCurrencies) {
            this.setState({
                totalAmount: null
            });
            //this.props.onGetTotals(selectedRows, this.props.allocationRecord.currencyCode, this.props.allocationRecord.paymentDate);
        } else {
            this.setState({
                totalAmount: totalAmount
            });
        }
    }

    render() {

        const loading = this.props.loading;

        const allocationRecordForm =
            <React.Fragment>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryName"
                        className="col-form-label col-lg-2"
                    >
                        Beneficiary name
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryName"
                            name="beneficiaryName"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.beneficiaryName}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.paymentBulks}
                                onClick={() => this.filterBySupplierName(this.props.allocationRecord.beneficiaryName)}
                            >
                                Filter by this name
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryRegNo"
                        className="col-form-label col-lg-2"
                    >
                        Beneficiary reg. No.
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryRegNo"
                            name="beneficiaryRegNo"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.beneficiaryRegNo}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.paymentBulks}
                                onClick={() => this.filterBySupplierRegNo(this.props.allocationRecord.beneficiaryRegNo)}
                            >
                                Filter by this reg. No.
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="amount"
                        className="col-form-label col-lg-2"
                    >
                        Unallocated amount
                    </Label>
                    <Col lg="4">
                        <Input
                            id="amount"
                            name="amount"
                            type="text"
                            className="form-control-plaintext"
                            value={
                                formatUtils.formatAmountWithDCSign(this.props.allocationRecord.unallocatedAmount, this.props.allocationRecord.isDebit)
                                + " "
                                + this.props.allocationRecord.currencyCode
                            }
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.paymentBulks}
                                onClick={() => this.filterByAmount(this.props.allocationRecord.unallocatedAmount)}
                            >
                                Filter by this amount
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="paymentDate"
                        className="col-form-label col-lg-2"
                    >
                        Payment date
                    </Label>
                    <Col lg="6">
                        <Input
                            id="paymentDate"
                            name="paymentDate"
                            type="text"
                            className="form-control-plaintext"
                            value={formatUtils.formatLocalDate(this.props.allocationRecord.paymentDate)}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="paymentDetails"
                        className="col-form-label col-lg-2"
                    >
                        Payment details
                    </Label>
                    <Col lg="6">
                        <Input
                            id="paymentDetails"
                            name="paymentDetails"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.paymentDetails}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                </FormGroup>
            </React.Fragment>

        const columns = [
            {
                ...columnsPaymentBulk.supplierName,
                filter: textFilter({ getFilter: (filter) => { this.supplierNameFilter = filter; } }),
            },
            {
                ...columnsPaymentBulk.supplierRegNo,
                filter: textFilter({ getFilter: (filter) => { this.supplierRegNoFilter = filter; } }),
            },
            columnsPaymentBulk.currencyCode,
            {
                ...columnsPaymentBulk.totalAmount,
                filter: textFilter({ getFilter: (filter) => { this.amountFilter = filter; } }),
                formatter: (cell, row) => formatUtils.formatAmountWithDCSign(-cell)
            },
            columnsPaymentBulk.paymentDate,
            columnsPaymentBulk.paymentDetails
        ];

        const selectRow = {
            mode: 'checkbox',

            onSelect: (row, isSelect, rowIndex, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
                this.setState({
                    selectedRows: selectedRows
                });
                this.onCalculateTotals(selectedRows);
            },

            onSelectAll: (isSelect, rows, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect)
                this.setState({
                    selectedRows: selectedRows
                });
                this.onCalculateTotals(selectedRows);
            }
        };

        const table =
            <div className="mt-3">
                <BootstrapTable
                    keyField='id'
                    data={this.props.paymentBulks}
                    columns={columns}
                    pagination={paginationFactory({ sizePerPageList: [5, 10, 25] })}
                    filter={filterFactory()}
                    selectRow={selectRow}
                    condensed
                />
            </div>

        const showAmountExceedAlert = (this.state.totalAmount && this.state.totalAmount - this.props.allocationRecord.unallocatedAmount > 0.01 && this.state.selectedRows.length > 1);

        const amountExceedAlert = (
            <Alert
                color="warning"
            >
                You have selected multiple invoices with total amount of {formatUtils.formatAmountWithDCSign(-this.state.totalAmount)} {this.props.allocationRecord.currencyCode},
                which exceeds the payment amount of {formatUtils.formatAmountWithDCSign(this.props.allocationRecord.unallocatedAmount, this.props.allocationRecord.isDebit)} {this.props.allocationRecord.currencyCode}.
                If you continue, some of those invoices will not be allocated with the payment or will be allocated partially.
            </Alert>
        );

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.props.onClose}
                >
                </Backdrop>
                <div
                    className={classes.PageCardContainer}
                >
                    <Card
                        // className={classes.PageCard} // Like in ColumnSelectionForm, for some reason specifying these parameters in CSS does not work 
                        style={{
                            height: "100%",
                            overflowX: "auto",
                            overflowY: "auto"
                        }}
                    >
                        {showAmountExceedAlert ? amountExceedAlert : null}
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    Select a payment
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.props.onClose}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>
                            {this.props.allocationRecord ? allocationRecordForm : null}

                            {loading ? (
                                <div width="100%" height="100%" align="center">
                                    {editFormControls.bigSpinner()}
                                    <br /><br />
                                </div>
                            ) : null}

                            {!loading && this.props.paymentBulks ? table : null}

                            <Container fluid>
                                <Row>
                                    <Col lg="2">
                                        <div style={{
                                            display: "flex",
                                            height: "100%",
                                            alignItems: "end"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                height: "100%",
                                                alignItems: "center"
                                            }}>
                                                {this.props.loadingTotals ? editFormControls.buttonSpinner() : null}
                                            </div>
                                            <Label>
                                                {this.props.allocationRecord && this.state.totalAmount && !this.props.loadingTotals ? formatUtils.formatAmountWithDCSign(-this.state.totalAmount) + " " + this.props.allocationRecord.currencyCode : null}
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Button
                                            color="primary"
                                            onClick={this.onCreateAllocation}
                                            disabled={this.state.selectedRows.length == 0 || this.props.loadingTotals}
                                        >
                                            Allocate
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                            <br />
                            <br />
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ allocation, payment, paymentBulk }) => ({
    allocationRecord: allocation.allocationRecord,
    loading: paymentBulk.loading,
    loadingTotals: payment.loadingTotals,
    paymentBulks: paymentBulk.paymentBulks,
    totals: payment.totals,
    error: paymentBulk.error,
});

const mapDispatchToProps = dispatch => ({
    onGetUnallocatedPayments: () => dispatch(actions.paymentBulkGetUnallocated()),
    /*onGetTotals: (selectedInvoices, currencyCode, date) => dispatch(actions.paymentGetTotalsForUnallocated(selectedInvoices, currencyCode, date)),*/
    onCreateAllocation: (allocation) => dispatch(actionsAllocation.allocationCreate(allocation))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectPayment);