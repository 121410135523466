export const CUSTOMER_INVOICE_GET_ALL = "CUSTOMER_INVOICE_GET_ALL";
export const CUSTOMER_INVOICE_GET_BY_ID = "CUSTOMER_INVOICE_GET_BY_ID";
export const CUSTOMER_INVOICE_GET_ERROR = "CUSTOMER_INVOICE_GET_ERROR";
export const CUSTOMER_INVOICE_GET_OVERDUE = "CUSTOMER_INVOICE_GET_OVERDUE";
export const CUSTOMER_INVOICE_GET_SUCCESS = "CUSTOMER_INVOICE_GET_SUCCESS";
export const CUSTOMER_INVOICE_GET_TOTALS_FOR_UNALLOCATED = "CUSTOMER_INVOICE_GET_TOTALS_FOR_UNALLOCATED";
export const CUSTOMER_INVOICE_GET_TOTALS_SUCCESS = "CUSTOMER_INVOICE_GET_TOTALS_SUCCESS";
export const CUSTOMER_INVOICE_GET_UNALLOCATED = "CUSTOMER_INVOICE_GET_UNALLOCATED";

export const CUSTOMER_INVOICE_COPY = "CUSTOMER_INVOICE_COPY";
export const CUSTOMER_INVOICE_COPY_SUCCESS = "CUSTOMER_INVOICE_COPY_SUCCESS";
export const CUSTOMER_INVOICE_COPY_ERROR = "CUSTOMER_INVOICE_COPY_ERROR";

export const CUSTOMER_INVOICE_CREATE = "CUSTOMER_INVOICE_CREATE";
export const CUSTOMER_INVOICE_CREATE_ERROR = "CUSTOMER_INVOICE_CREATE_ERROR";
export const CUSTOMER_INVOICE_CREATE_SUCCESS = "CUSTOMER_INVOICE_CREATE_SUCCESS";

export const CUSTOMER_INVOICE_DELETE = "CUSTOMER_INVOICE_DELETE";
export const CUSTOMER_INVOICE_DELETE_LIST = "CUSTOMER_INVOICE_DELETE_LIST";
export const CUSTOMER_INVOICE_DELETE_ERROR = "CUSTOMER_INVOICE_DELETE_ERROR";
export const CUSTOMER_INVOICE_DELETE_SUCCESS = "CUSTOMER_INVOICE_DELETE_SUCCESS";

export const CUSTOMER_INVOICE_PRINT_LIST_TO_PDF = "CUSTOMER_INVOICE_PRINT_LIST_TO_PDF";

export const CUSTOMER_INVOICE_PRINT_TO_PDF = "CUSTOMER_INVOICE_PRINT_TO_PDF";
export const CUSTOMER_INVOICE_PRINT_TO_PDF_ERROR = "CUSTOMER_INVOICE_PRINT_TO_PDF_ERROR";
export const CUSTOMER_INVOICE_PRINT_TO_PDF_SUCCESS = "CUSTOMER_INVOICE_PRINT_TO_PDF_SUCCESS";

export const CUSTOMER_INVOICE_SEND_LIST = "CUSTOMER_INVOICE_SEND_LIST";
export const CUSTOMER_INVOICE_SEND_ERROR = "CUSTOMER_INVOICE_SEND_ERROR";
export const CUSTOMER_INVOICE_SEND_SUCCESS = "CUSTOMER_INVOICE_SEND_SUCCESS";

export const CUSTOMER_INVOICE_SEND_REMINDERS_LIST = "CUSTOMER_INVOICE_SEND_REMINDERS_LIST";
export const CUSTOMER_INVOICE_SEND_REMINDERS_ERROR = "CUSTOMER_INVOICE_SEND_REMINDERS_ERROR";
export const CUSTOMER_INVOICE_SEND_REMINDERS_SUCCESS = "CUSTOMER_INVOICE_SEND_REMINDERS_SUCCESS";


export const CUSTOMER_INVOICE_LINE_GET_ALL = "CUSTOMER_INVOICE_LINE_GET_ALL";
export const CUSTOMER_INVOICE_LINE_GET_BY_ID = "CUSTOMER_INVOICE_LINE_GET_BY_ID";
export const CUSTOMER_INVOICE_LINE_GET_IN_PROCESS = "CUSTOMER_INVOICE_LINE_GET_IN_PROCESS";
export const CUSTOMER_INVOICE_LINE_GET_ERROR = "CUSTOMER_INVOICE_LINE_GET_ERROR";
export const CUSTOMER_INVOICE_LINE_GET_SUCCESS = "CUSTOMER_INVOICE_LINE_GET_SUCCESS";

export const CUSTOMER_INVOICE_LINE_MOVE_UP = "CUSTOMER_INVOICE_LINE_MOVE_UP";
export const CUSTOMER_INVOICE_LINE_MOVE_DOWN = "CUSTOMER_INVOICE_LINE_MOVE_DOWN";
export const CUSTOMER_INVOICE_LINE_COPY = "CUSTOMER_INVOICE_LINE_COPY";
export const CUSTOMER_INVOICE_LINE_MOVE_SUCCESS = "CUSTOMER_INVOICE_LINE_MOVE_SUCCESS";
export const CUSTOMER_INVOICE_LINE_MOVE_ERROR = "CUSTOMER_INVOICE_LINE_MOVE_ERROR";

export const CUSTOMER_INVOICE_LINE_CREATE = "CUSTOMER_INVOICE_LINE_CREATE";
export const CUSTOMER_INVOICE_LINE_CREATE_ERROR = "CUSTOMER_INVOICE_LINE_CREATE_ERROR";
export const CUSTOMER_INVOICE_LINE_CREATE_SUCCESS = "CUSTOMER_INVOICE_LINE_CREATE_SUCCESS";

export const CUSTOMER_INVOICE_LINE_DELETE = "CUSTOMER_INVOICE_LINE_DELETE";
export const CUSTOMER_INVOICE_LINE_DELETE_LIST = "CUSTOMER_INVOICE_LINE_DELETE_LIST";
export const CUSTOMER_INVOICE_LINE_DELETE_SUCCESS = "CUSTOMER_INVOICE_LINE_DELETE_SUCCESS";
export const CUSTOMER_INVOICE_LINE_DELETE_ERROR = "CUSTOMER_INVOICE_LINE_DELETE_ERROR";

