// 2024.10.03: "code" values are not used, do not correspond to any backend values and can be safely changed anytime
export const SupplierInvoiceCandidateStatus = [
	{ code: 1, id: "READY", description: "Ready" }, 
	{ code: 2, id: "DATA_TO_UPDATE", description: "Data to update" }, 
	{ code: 3, id: "DATA_ERRORS", description: "Data errors" }, 
];

export const formatSupplierInvoiceCandidateStatusColor = (status) => {
    switch (status) {
        case "DATA_ERRORS":
            return "danger";
        case "DATA_TO_UPDATE":
            return "warning";
        case "READY":
            return "success";
        default:
            return "secondary";
    }
}