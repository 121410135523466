import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCompanySettings from "store/CompanySettings/actions";
import * as actionsCountry from "store/Country/actions";
import * as actionsCurrency from "store/Currency/actions";
import * as actionsVATRate from "store/VATRate/actions";
import Breadcrumbs from "components/Common/Breadcrumb";
import * as chargeBearer from "definitions/enums/ChargeBearer";
import * as config from "../../config";
import * as editFormControls from "helpers/editFormControls";
import { EditFormControlWithLabel } from "helpers/editFormControls";
import * as endpointsFrontend from "definitions/endpoints/endpoints-frontend";
import * as inputSelectUtils from "helpers/inputSelectUtils";
import * as mergeStatementPeriod from "definitions/enums/MergeStatementPeriod";
import { PaymentDateToUse } from "definitions/enums/PaymentDateToUse";
import timezones from "definitions/timezones.json";

class CompanySettingsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            localCurrencyId: "",
            addressCountryId: "",
            addressCountryCode: "",
            addressRegion: "",
            addressCity: "",
            addressPostCode: "",
            addressStreet: "",
            phoneNo: "",
            websiteUrl: "",
            dateFrom: "",
            paymentDetailsTemplateIntlPmt: "",
            paymentDetailsTemplateLocalPmt: "",
            paymentDateToUse: "",
            paymentsConvertToAccountCurrency: "",
            paymentsDoGroup: "",
            defaultVATRateIdForLocalCompanies: "",
            vatRoundingMethod: "LINE_BY_LINE",
            ccAllEmailsTo: "",
            paymentIdPrefix: "",
            defaultChargeBearer: "",
            paymentsDefaultPurposeCode: "",
            paymentsUsePurposeCodeFromInvoice: false,
            paymentsAutoSetSHARToSEPA: "",
            exportStatementToFile: "",
            statementExportTemplate_Outgoing: "",
            statementExportTemplate_OutgoingAndCharges: "",
            statementExportTemplate_Incoming: "",
            statementExportTemplate_IncomingAndCharges: "",
            statementExportTemplate_Charges: "",
            statementExportFilename_Outgoing: "PL.TXT",
            statementExportFilename_OutgoingAndCharges: "PL_GL.TXT",
            statementExportFilename_Incoming: "SL.TXT",
            statementExportFilename_IncomingAndCharges: "SL_GL.TXT",
            statementExportFilename_Charges: "GL.TXT",
            statementExportPath_Outgoing: "",
            statementExportPath_OutgoingAndCharges: "",
            statementExportPath_Incoming: "",
            statementExportPath_IncomingAndCharges: "",
            statementExportPath_Charges: "",
            statementExportFileAppendIfExists_Outgoing: false,
            statementExportFileAppendIfExists_OutgoingAndCharges: false,
            statementExportFileAppendIfExists_Incoming: false,
            statementExportFileAppendIfExists_IncomingAndCharges: false,
            statementExportFileAppendIfExists_Charges: false,
            mergeStatementPeriod: "",
            localTimezone: "",
            useAdvanceNo: false,
            useCustomerSupplierExternalAccount: false,
            autoConfirmAndExportHighTrustAllocations: false,
            emailToNotifyOnDataExport: "",
            allocateToSupplierInvoiceUnpaidAmounts: false,

            useExportTemplate_Outgoing: false,
            useExportTemplate_OutgoingAndCharges: false,
            useExportTemplate_Incoming: false,
            useExportTemplate_IncomingAndCharges: false,
            useExportTemplate_Charges: false,
            useEmailToNotifyOnDataExport: false,

            changed: false,
            companySettings: [],
            error: null,
            warningText: "",
            warningLink: "",
            warningLinkText: ""
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            changed: true,
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedCompanySettings = {
            localCurrencyId: this.state.localCurrencyId,
            addressCountryId: this.state.addressCountryId,
            addressRegion: this.state.addressRegion,
            addressCity: this.state.addressCity,
            addressPostCode: this.state.addressPostCode,
            addressStreet: this.state.addressStreet,
            phoneNo: this.state.phoneNo,
            websiteUrl: this.state.websiteUrl,
            paymentDetailsTemplateIntlPmt: this.state.paymentDetailsTemplateIntlPmt,
            paymentDetailsTemplateLocalPmt: this.state.paymentDetailsTemplateLocalPmt,
            paymentDateToUse: this.state.paymentDateToUse,
            paymentsConvertToAccountCurrency: this.state.paymentsConvertToAccountCurrency,
            paymentsDoGroup: this.state.paymentsDoGroup,
            defaultVATRateIdForLocalCompanies: this.state.defaultVATRateIdForLocalCompanies,
            vatRoundingMethod: this.state.vatRoundingMethod,
            ccAllEmailsTo: this.state.ccAllEmailsTo,
            paymentIdPrefix: this.state.paymentIdPrefix,
            defaultChargeBearer: this.state.defaultChargeBearer,
            paymentsDefaultPurposeCode: this.state.paymentsDefaultPurposeCode,
            paymentsUsePurposeCodeFromInvoice: this.state.paymentsUsePurposeCodeFromInvoice,
            paymentsAutoSetSHARToSEPA: this.state.paymentsAutoSetSHARToSEPA,
            exportStatementToFile: this.state.exportStatementToFile,
            statementExportTemplate_Outgoing: this.state.useExportTemplate_Outgoing ? this.state.statementExportTemplate_Outgoing : "",
            statementExportTemplate_OutgoingAndCharges: this.state.useExportTemplate_OutgoingAndCharges ? this.state.statementExportTemplate_OutgoingAndCharges : "",
            statementExportTemplate_Incoming: this.state.useExportTemplate_Incoming ? this.state.statementExportTemplate_Incoming : "",
            statementExportTemplate_IncomingAndCharges: this.state.useExportTemplate_IncomingAndCharges ? this.state.statementExportTemplate_IncomingAndCharges : "",
            statementExportTemplate_Charges: this.state.useExportTemplate_Charges ? this.state.statementExportTemplate_Charges : "",
            statementExportFilename_Outgoing: this.state.useExportTemplate_Outgoing ? this.state.statementExportFilename_Outgoing : "",
            statementExportFilename_OutgoingAndCharges: this.state.useExportTemplate_OutgoingAndCharges ? this.state.statementExportFilename_OutgoingAndCharges : "",
            statementExportFilename_Incoming: this.state.useExportTemplate_Incoming ? this.state.statementExportFilename_Incoming : "",
            statementExportFilename_IncomingAndCharges: this.state.useExportTemplate_IncomingAndCharges ? this.state.statementExportFilename_IncomingAndCharges : "",
            statementExportFilename_Charges: this.state.useExportTemplate_Charges ? this.state.statementExportFilename_Charges : "",
            statementExportPath_Outgoing: this.state.statementExportPath_Outgoing,
            statementExportPath_OutgoingAndCharges: this.state.statementExportPath_OutgoingAndCharges,
            statementExportPath_Incoming: this.state.statementExportPath_Incoming,
            statementExportPath_IncomingAndCharges: this.state.statementExportPath_IncomingAndCharges,
            statementExportPath_Charges: this.state.statementExportPath_Charges,
            statementExportFileAppendIfExists_Outgoing: this.state.statementExportFileAppendIfExists_Outgoing,
            statementExportFileAppendIfExists_OutgoingAndCharges: this.state.statementExportFileAppendIfExists_OutgoingAndCharges,
            statementExportFileAppendIfExists_Incoming: this.state.statementExportFileAppendIfExists_Incoming,
            statementExportFileAppendIfExists_IncomingAndCharges: this.state.statementExportFileAppendIfExists_IncomingAndCharges,
            statementExportFileAppendIfExists_Charges: this.state.statementExportFileAppendIfExists_Charges,
            mergeStatementPeriod: this.state.mergeStatementPeriod,
            localTimezone: this.state.localTimezone,
            useAdvanceNo: this.state.useAdvanceNo,
            useCustomerSupplierExternalAccount: this.state.useCustomerSupplierExternalAccount,
            autoConfirmAndExportHighTrustAllocations: this.state.autoConfirmAndExportHighTrustAllocations,
            emailToNotifyOnDataExport: this.state.useEmailToNotifyOnDataExport ? this.state.emailToNotifyOnDataExport : "",
            allocateToSupplierInvoiceUnpaidAmounts: this.state.allocateToSupplierInvoiceUnpaidAmounts,
        };
        if (this.state.id) {
            newOrUpdatedCompanySettings = {
                id: this.state.id,
                ...newOrUpdatedCompanySettings
            }
        }
        this.setState({
            changed: false
        }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
        this.props.onCreateCompanySettings(newOrUpdatedCompanySettings, this.props.history);
    }

    componentDidMount() {
        this.props.onGetCompanySettings();
        this.props.onGetCountries();
        this.props.onGetCurrencies();
        this.props.onGetVATRates();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            let error = this.props.error;
            this.setState({
                error: error
            });
        }

        // VAT rates, countries and currencies are checked in the reverse order to the priorities 
        // in which the warning messages about their absense should be displayed.
        // E.g. if neither currencies, countries or VAT rates are entered, the user should see the message that currencies are empty,
        // and only if some currencies are present, they should see the message that countries are empty, and so on.

        if (prevProps.vatRates !== this.props.vatRates) {
            if (this.props.vatRates && this.props.vatRates.length) {
                if (!this.state.defaultVATRateIdForLocalCompanies) {
                    this.setState({
                        defaultVATRateIdForLocalCompanies: this.props.vatRates[0].id
                    })
                }
            } else {
                this.setState({
                    warningText: "No VAT rates available.",
                    warningLink: endpointsFrontend.VAT_RATE_NEW,
                    warningLinkText: "Click here to enter a new VAT rate."
                });
            }
        }

        if (prevProps.countries !== this.props.countries) {
            if (this.props.countries && this.props.countries.length) {
                if (!this.state.addressCountryId) {
                    this.setState({
                        addressCountryId: this.props.countries[0].id
                    });
                }
            } else {
                this.setState({
                    warningText: "No countries available.",
                    warningLink: endpointsFrontend.COUNTRY_NEW,
                    warningLinkText: "Click here to enter a new country."
                });
            }
        }

        if (prevProps.currencies !== this.props.currencies) {
            if (this.props.currencies && this.props.currencies.length) {
                if (!this.state.localCurrencyId) {
                    this.setState({
                        localCurrencyId: this.props.currencies[0].id
                    });
                }
            } else {
                this.setState({
                    warningText: "No currencies available.",
                    warningLink: endpointsFrontend.CURRENCY_NEW,
                    warningLinkText: "Click here to enter a new currency."
                });
            }
        }

        if (prevProps.companySettings !== this.props.companySettings) {
            if (this.props.companySettings) {
                this.setState({
                    id: this.props.companySettings.id,
                    addressCountryCode: this.props.companySettings.addressCountryCode,
                    addressRegion: this.props.companySettings.addressRegion,
                    addressCity: this.props.companySettings.addressCity,
                    addressPostCode: this.props.companySettings.addressPostCode,
                    addressStreet: this.props.companySettings.addressStreet,
                    phoneNo: this.props.companySettings.phoneNo,
                    websiteUrl: this.props.companySettings.websiteUrl,
                    dateFrom: this.props.companySettings.dateFrom,
                    paymentDetailsTemplateIntlPmt: this.props.companySettings.paymentDetailsTemplateIntlPmt,
                    paymentDetailsTemplateLocalPmt: this.props.companySettings.paymentDetailsTemplateLocalPmt,
                    paymentDateToUse: this.props.companySettings.paymentDateToUse,
                    paymentsConvertToAccountCurrency: this.props.companySettings.paymentsConvertToAccountCurrency,
                    paymentsDoGroup: this.props.companySettings.paymentsDoGroup,
                    vatRoundingMethod: this.props.companySettings.vatRoundingMethod,
                    ccAllEmailsTo: this.props.companySettings.ccAllEmailsTo,
                    paymentIdPrefix: this.props.companySettings.paymentIdPrefix,
                    defaultChargeBearer: this.props.companySettings.defaultChargeBearer,
                    paymentsDefaultPurposeCode: this.props.companySettings.paymentsDefaultPurposeCode,
                    paymentsUsePurposeCodeFromInvoice: this.props.companySettings.paymentsUsePurposeCodeFromInvoice,
                    paymentsAutoSetSHARToSEPA: this.props.companySettings.paymentsAutoSetSHARToSEPA,
                    exportStatementToFile: this.props.companySettings.exportStatementToFile,
                    statementExportTemplate_Outgoing: this.props.companySettings.statementExportTemplate_Outgoing,
                    statementExportTemplate_OutgoingAndCharges: this.props.companySettings.statementExportTemplate_OutgoingAndCharges,
                    statementExportTemplate_Incoming: this.props.companySettings.statementExportTemplate_Incoming,
                    statementExportTemplate_IncomingAndCharges: this.props.companySettings.statementExportTemplate_IncomingAndCharges,
                    statementExportTemplate_Charges: this.props.companySettings.statementExportTemplate_Charges,
                    statementExportFilename_Outgoing: this.props.companySettings.statementExportFilename_Outgoing,
                    statementExportFilename_OutgoingAndCharges: this.props.companySettings.statementExportFilename_OutgoingAndCharges,
                    statementExportFilename_Incoming: this.props.companySettings.statementExportFilename_Incoming,
                    statementExportFilename_IncomingAndCharges: this.props.companySettings.statementExportFilename_IncomingAndCharges,
                    statementExportFilename_Charges: this.props.companySettings.statementExportFilename_Charges,
                    statementExportPath_Outgoing: this.props.companySettings.statementExportPath_Outgoing,
                    statementExportPath_OutgoingAndCharges: this.props.companySettings.statementExportPath_OutgoingAndCharges,
                    statementExportPath_Incoming: this.props.companySettings.statementExportPath_Incoming,
                    statementExportPath_IncomingAndCharges: this.props.companySettings.statementExportPath_IncomingAndCharges,
                    statementExportPath_Charges: this.props.companySettings.statementExportPath_Charges,
                    statementExportFileAppendIfExists_Outgoing: this.props.companySettings.statementExportFileAppendIfExists_Outgoing,
                    statementExportFileAppendIfExists_OutgoingAndCharges: this.props.companySettings.statementExportFileAppendIfExists_OutgoingAndCharges,
                    statementExportFileAppendIfExists_Incoming: this.props.companySettings.statementExportFileAppendIfExists_Incoming,
                    statementExportFileAppendIfExists_IncomingAndCharges: this.props.companySettings.statementExportFileAppendIfExists_IncomingAndCharges,
                    statementExportFileAppendIfExists_Charges: this.props.companySettings.statementExportFileAppendIfExists_Charges,
                    mergeStatementPeriod: this.props.companySettings.mergeStatementPeriod,
                    localTimezone: this.props.companySettings.localTimezone,
                    useAdvanceNo: this.props.companySettings.useAdvanceNo,
                    useCustomerSupplierExternalAccount: this.props.companySettings.useCustomerSupplierExternalAccount,
                    autoConfirmAndExportHighTrustAllocations: this.props.companySettings.autoConfirmAndExportHighTrustAllocations,
                    emailToNotifyOnDataExport: this.props.companySettings.emailToNotifyOnDataExport,
                    allocateToSupplierInvoiceUnpaidAmounts: this.props.companySettings.allocateToSupplierInvoiceUnpaidAmounts,

                    useExportTemplate_Outgoing: this.props.companySettings.statementExportTemplate_Outgoing && this.props.companySettings.statementExportTemplate_Outgoing.length,
                    useExportTemplate_OutgoingAndCharges: this.props.companySettings.statementExportTemplate_OutgoingAndCharges && this.props.companySettings.statementExportTemplate_OutgoingAndCharges.length,
                    useExportTemplate_Incoming: this.props.companySettings.statementExportTemplate_Incoming && this.props.companySettings.statementExportTemplate_Incoming.length,
                    useExportTemplate_IncomingAndCharges: this.props.companySettings.statementExportTemplate_IncomingAndCharges && this.props.companySettings.statementExportTemplate_IncomingAndCharges.length,
                    useExportTemplate_Charges: this.props.companySettings.statementExportTemplate_Charges && this.props.companySettings.statementExportTemplate_Charges.length,
                    useEmailToNotifyOnDataExport: this.props.companySettings.emailToNotifyOnDataExport && this.props.companySettings.emailToNotifyOnDataExport.length,
                });
                if (this.props.companySettings.localCurrencyId) {
                    this.setState({
                        localCurrencyId: this.props.companySettings.localCurrencyId
                    });
                }
                if (this.props.companySettings.addressCountryId) {
                    this.setState({
                        addressCountryId: this.props.companySettings.addressCountryId
                    });
                }
                if (this.props.companySettings.defaultVATRateIdForLocalCompanies) {
                    this.setState({
                        defaultVATRateIdForLocalCompanies: this.props.companySettings.defaultVATRateIdForLocalCompanies
                    });
                }
            }
        }

        if ((prevProps.saveSuccess !== this.props.saveSuccess) || (prevProps.error !== this.props.error)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
    }

    render() {
        const pageTitle = "Edit company settings | " + config.AppName;
        const breadcrumbsTitle = "Company settings";
        const breadcrumbsItem = "Edit company settings";

        const countryOptions = inputSelectUtils.generateOptionsFromData(this.props.countries, countryRow => (countryRow.code + (countryRow.nameLang0 ? " (" + countryRow.nameLang0 + ")" : "")));
        const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));
        const vatRateOptions = inputSelectUtils.generateOptionsFromData(this.props.vatRates, vatRateRow => (vatRateRow.rate + "%"));
        const paymentDateOptions = inputSelectUtils.generateOptionsFromData(PaymentDateToUse, paymentDateRow => paymentDateRow.description);
        const chargeBearerOptions = inputSelectUtils.generateOptionsFromData(chargeBearer.ChargeBearer, chargeBearerRow => chargeBearerRow.description);
        const mergeStatementPeriodOptions = inputSelectUtils.generateOptionsFromData(mergeStatementPeriod.MergeStatementPeriod, row => row.description);
        const timezoneOptions = inputSelectUtils.generateOptionsFromData(timezones, row => row.TZ, (row1, row2) => (row1.TZ > row2.TZ ? 1 : row1.TZ === row2.TZ ? 0 : -1), "TZ");
        const loading = this.props.loadingCompanySettings || this.props.loadingCountries || this.props.loadingCurrencies || this.props.loadingVATRates;

        const doExportStatementToFile = this.state.exportStatementToFile !== "NONE";
        const doExportStatementToFileAutomatically = this.state.exportStatementToFile === "AUTOMATIC";
        const exportFilenameCaption = doExportStatementToFileAutomatically ? "Export file name" : "Default filename";
        const autoConfirmAndExportHighTrustAllocationsCaption = "Auto-confirm " + (doExportStatementToFileAutomatically ? "and export " : "") + "high-trust allocations";

        const appendFileHintText = (specifiedFileName) => {
            if (!specifiedFileName || !specifiedFileName.length) {
                specifiedFileName = "YourFileName.txt"
            }
            const example1 = specifiedFileName.includes(".")
                ? (specifiedFileName.substring(0, specifiedFileName.lastIndexOf(".")) + " (1)" + specifiedFileName.substring(specifiedFileName.lastIndexOf(".")))
                : specifiedFileName + " (1)";
            const example2 = specifiedFileName.includes(".")
                ? (specifiedFileName.substring(0, specifiedFileName.lastIndexOf(".")) + " (2)" + specifiedFileName.substring(specifiedFileName.lastIndexOf(".")))
                : specifiedFileName + " (2)";
            return ("If checked, External Data Connector will append a file if it already exists. If unchecked and the file exists, External Data Connector will create another file such as " + example1 + ", " + example2 + " and so on.");
        }

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.selectControl("localCurrencyId", "Local currency", this.onChange, this.state.localCurrencyId, currencyOptions)}
                                        {editFormControls.selectControl("addressCountryId", "Country", this.onChange, this.state.addressCountryId, countryOptions)}
                                        {editFormControls.selectControl("localTimezone", "Timezone", this.onChange, this.state.localTimezone, timezoneOptions)}

                                        <hr />

                                        {editFormControls.textControl("addressRegion", "Region", this.onChange, this.state.addressRegion)}
                                        {editFormControls.textControl("addressCity", "City", this.onChange, this.state.addressCity)}
                                        {editFormControls.textControl("addressPostCode", "Postal code", this.onChange, this.state.addressPostCode)}
                                        {editFormControls.textControl("addressStreet", "Street address", this.onChange, this.state.addressStreet)}
                                        {editFormControls.textControl("phoneNo", "Phone No.", this.onChange, this.state.phoneNo)}
                                        {editFormControls.textControl("websiteUrl", "Website URL", this.onChange, this.state.websiteUrl)}

                                        <hr />

                                        {editFormControls.textAreaControl("paymentDetailsTemplateIntlPmt", "Payment details template (international payments)", this.onChange, this.state.paymentDetailsTemplateIntlPmt)}
                                        {editFormControls.textAreaControl("paymentDetailsTemplateLocalPmt", "Payment details template (local payments)", this.onChange, this.state.paymentDetailsTemplateLocalPmt)}
                                        {editFormControls.selectControl("paymentDateToUse", "Payment date to use", this.onChange, this.state.paymentDateToUse, paymentDateOptions)}
                                        {editFormControls.selectControl("defaultChargeBearer", "Default charge bearer", this.onChange, this.state.defaultChargeBearer, chargeBearerOptions)}

                                        {editFormControls.checkboxControl("paymentsUsePurposeCodeFromInvoice", "Use purpose code from invoice", this.onChange, this.state.paymentsUsePurposeCodeFromInvoice, "If checked, payment purpose code will be read from supplier invoice. Currently only available for supplier invoices read from an external system (e.g. an ERP). If you are not using CashManager with an external system or want to always use a specified payment purpose code, do not check it and fill in the next field 'Default payment purpose code'.")}
                                        {!this.state.paymentsUsePurposeCodeFromInvoice && editFormControls.textControl("paymentsDefaultPurposeCode", "Default payment purpose code", this.onChange, this.state.paymentsDefaultPurposeCode)}

                                        {editFormControls.checkboxControl("paymentsAutoSetSHARToSEPA", "Automatically set charge bearer to SHAR for SEPA payments", this.onChange, this.state.paymentsAutoSetSHARToSEPA)}
                                        {editFormControls.checkboxControl("paymentsConvertToAccountCurrency", "Convert payments to account currency", this.onChange, this.state.paymentsConvertToAccountCurrency)}
                                        {editFormControls.checkboxControl("paymentsDoGroup", "Group payments by default", this.onChange, this.state.paymentsDoGroup, "If checked, invoice payments to the same supplier, same bank account, same currency and same payment date will be grouped into a single payment. If not checked, a payment will be created for each supplier invoice.")}
                                        {editFormControls.textControl("paymentIdPrefix", "Payment ID prefix", this.onChange, this.state.paymentIdPrefix)}

                                        <hr />

                                        {editFormControls.selectControl("defaultVATRateIdForLocalCompanies", "Default VAT rate for local companies", this.onChange, this.state.defaultVATRateIdForLocalCompanies, vatRateOptions)}
                                        <EditFormControlWithLabel fieldTitle="VAT rounding method">
                                            {editFormControls.radioButton("vatRoundingMethod", this.onChange, this.state.vatRoundingMethod, "LINE_BY_LINE", "Line-by-line")}
                                            {editFormControls.radioButton("vatRoundingMethod", this.onChange, this.state.vatRoundingMethod, "BY_TOTAL", "By total amount")}
                                        </EditFormControlWithLabel>
                                        {editFormControls.textControl("ccAllEmailsTo", "CC all emails to", this.onChange, this.state.ccAllEmailsTo, "Enter email address to CC all emails")}
                                        {editFormControls.selectControl("mergeStatementPeriod", "Merge statements", this.onChange, this.state.mergeStatementPeriod, mergeStatementPeriodOptions)}

                                        <hr />

                                        <EditFormControlWithLabel fieldTitle="Export statement to file">
                                            {editFormControls.radioButton("exportStatementToFile", this.onChange, this.state.exportStatementToFile, "NONE", "None")}
                                            {editFormControls.radioButton("exportStatementToFile", this.onChange, this.state.exportStatementToFile, "MANUAL", "Manual")}
                                            {editFormControls.radioButton("exportStatementToFile", this.onChange, this.state.exportStatementToFile, "AUTOMATIC", "Automatic")}
                                        </EditFormControlWithLabel>

                                        {doExportStatementToFileAutomatically && editFormControls.checkboxControl("useEmailToNotifyOnDataExport", "Notify on email upon data export", this.onChange, this.state.useEmailToNotifyOnDataExport)}
                                        {doExportStatementToFileAutomatically && this.state.useEmailToNotifyOnDataExport && editFormControls.textControl("emailToNotifyOnDataExport", "Email address", this.onChange, this.state.emailToNotifyOnDataExport)}
                                        {doExportStatementToFile && (<hr />)}

                                        {doExportStatementToFile && editFormControls.checkboxControl("useExportTemplate_Outgoing", "Outgoing payments", this.onChange, this.state.useExportTemplate_Outgoing)}
                                        {doExportStatementToFile && this.state.useExportTemplate_Outgoing && editFormControls.textAreaControl("statementExportTemplate_Outgoing", "Template", this.onChange, this.state.statementExportTemplate_Outgoing)}
                                        {doExportStatementToFile && this.state.useExportTemplate_Outgoing && editFormControls.textControl("statementExportFilename_Outgoing", exportFilenameCaption, this.onChange, this.state.statementExportFilename_Outgoing, "PL.txt")}
                                        {doExportStatementToFile && this.state.useExportTemplate_Outgoing && doExportStatementToFileAutomatically && editFormControls.textControl("statementExportPath_Outgoing", "Export file path", this.onChange, this.state.statementExportPath_Outgoing, null, null, !(this.state.statementExportPath_Outgoing && this.state.statementExportPath_Outgoing.length))}
                                        {doExportStatementToFileAutomatically && this.state.useExportTemplate_Outgoing && editFormControls.checkboxControl("statementExportFileAppendIfExists_Outgoing", "Append file if exists", this.onChange, this.state.statementExportFileAppendIfExists_Outgoing, appendFileHintText(this.state.statementExportFilename_Outgoing))}
                                        {doExportStatementToFile && (<hr />)}

                                        {doExportStatementToFile && editFormControls.checkboxControl("useExportTemplate_OutgoingAndCharges", "Outgoing payments and bank charges", this.onChange, this.state.useExportTemplate_OutgoingAndCharges)}
                                        {doExportStatementToFile && this.state.useExportTemplate_OutgoingAndCharges && editFormControls.textAreaControl("statementExportTemplate_OutgoingAndCharges", "Template", this.onChange, this.state.statementExportTemplate_OutgoingAndCharges)}
                                        {doExportStatementToFile && this.state.useExportTemplate_OutgoingAndCharges && editFormControls.textControl("statementExportFilename_OutgoingAndCharges", exportFilenameCaption, this.onChange, this.state.statementExportFilename_OutgoingAndCharges, "PL_GL.txt")}
                                        {doExportStatementToFile && this.state.useExportTemplate_OutgoingAndCharges && doExportStatementToFileAutomatically && editFormControls.textControl("statementExportPath_OutgoingAndCharges", "Export file path", this.onChange, this.state.statementExportPath_OutgoingAndCharges, null, null, !(this.state.statementExportPath_OutgoingAndCharges && this.state.statementExportPath_OutgoingAndCharges.length))}
                                        {doExportStatementToFileAutomatically && this.state.useExportTemplate_OutgoingAndCharges && editFormControls.checkboxControl("statementExportFileAppendIfExists_OutgoingAndCharges", "Append file if exists", this.onChange, this.state.statementExportFileAppendIfExists_OutgoingAndCharges, appendFileHintText(this.state.statementExportFilename_OutgoingAndCharges))}
                                        {doExportStatementToFile && (<hr />)}

                                        {doExportStatementToFile && editFormControls.checkboxControl("useExportTemplate_Incoming", "Incoming payments", this.onChange, this.state.useExportTemplate_Incoming)}
                                        {doExportStatementToFile && this.state.useExportTemplate_Incoming && editFormControls.textAreaControl("statementExportTemplate_Incoming", "Template", this.onChange, this.state.statementExportTemplate_Incoming)}
                                        {doExportStatementToFile && this.state.useExportTemplate_Incoming && editFormControls.textControl("statementExportFilename_Incoming", exportFilenameCaption, this.onChange, this.state.statementExportFilename_Incoming, "SL.txt")}
                                        {doExportStatementToFile && this.state.useExportTemplate_Incoming && doExportStatementToFileAutomatically && editFormControls.textControl("statementExportPath_Incoming", "Export file path", this.onChange, this.state.statementExportPath_Incoming, null, null, !(this.state.statementExportPath_Incoming && this.state.statementExportPath_Incoming.length))}
                                        {doExportStatementToFileAutomatically && this.state.useExportTemplate_Incoming && editFormControls.checkboxControl("statementExportFileAppendIfExists_Incoming", "Append file if exists", this.onChange, this.state.statementExportFileAppendIfExists_Incoming, appendFileHintText(this.state.statementExportFilename_Incoming))}
                                        {doExportStatementToFile && (<hr />)}

                                        {doExportStatementToFile && editFormControls.checkboxControl("useExportTemplate_IncomingAndCharges", "Incoming payments and bank charges", this.onChange, this.state.useExportTemplate_IncomingAndCharges)}
                                        {doExportStatementToFile && this.state.useExportTemplate_IncomingAndCharges && editFormControls.textAreaControl("statementExportTemplate_IncomingAndCharges", "Template", this.onChange, this.state.statementExportTemplate_IncomingAndCharges)}
                                        {doExportStatementToFile && this.state.useExportTemplate_IncomingAndCharges && editFormControls.textControl("statementExportFilename_IncomingAndCharges", exportFilenameCaption, this.onChange, this.state.statementExportFilename_IncomingAndCharges, "SL_GL.txt")}
                                        {doExportStatementToFile && this.state.useExportTemplate_IncomingAndCharges && doExportStatementToFileAutomatically && editFormControls.textControl("statementExportPath_IncomingAndCharges", "Export file path", this.onChange, this.state.statementExportPath_IncomingAndCharges, null, null, !(this.state.statementExportPath_IncomingAndCharges && this.state.statementExportPath_IncomingAndCharges.length))}
                                        {doExportStatementToFileAutomatically && this.state.useExportTemplate_IncomingAndCharges && editFormControls.checkboxControl("statementExportFileAppendIfExists_IncomingAndCharges", "Append file if exists", this.onChange, this.state.statementExportFileAppendIfExists_IncomingAndCharges, appendFileHintText(this.state.statementExportFilename_IncomingAndCharges))}
                                        {doExportStatementToFile && (<hr />)}

                                        {doExportStatementToFile && editFormControls.checkboxControl("useExportTemplate_Charges", "Bank charges", this.onChange, this.state.useExportTemplate_Charges)}
                                        {doExportStatementToFile && this.state.useExportTemplate_Charges && editFormControls.textAreaControl("statementExportTemplate_Charges", "Statement export template (Bank charges)", this.onChange, this.state.statementExportTemplate_Charges)}
                                        {doExportStatementToFile && this.state.useExportTemplate_Charges && editFormControls.textControl("statementExportFilename_Charges", exportFilenameCaption, this.onChange, this.state.statementExportFilename_Charges, "GL.txt")}
                                        {doExportStatementToFile && this.state.useExportTemplate_Charges && doExportStatementToFileAutomatically && editFormControls.textControl("statementExportPath_Charges", "Export file path", this.onChange, this.state.statementExportPath_Charges, null, null, !(this.state.statementExportPath_Charges && this.state.statementExportPath_Charges.length))}
                                        {doExportStatementToFileAutomatically && this.state.useExportTemplate_Charges && editFormControls.checkboxControl("statementExportFileAppendIfExists_Charges", "Append file if exists", this.onChange, this.state.statementExportFileAppendIfExists_Charges, appendFileHintText(this.state.statementExportFilename_Charges))}
                                        {doExportStatementToFile && (<hr />)}

                                        {doExportStatementToFile && editFormControls.checkboxControl("useAdvanceNo", "Use advance No.", this.onChange, this.state.useAdvanceNo, "If checked, CashManager will require you to input an advance number when manually allocating a payment to a customer or a supplier. The advance number can then be exported by the External Data Connector to your external system, which might require it (e.g. Epicor iScala).")}
                                        {doExportStatementToFile && editFormControls.checkboxControl("useCustomerSupplierExternalAccount", "Use customer/supplier external account", this.onChange, this.state.useCustomerSupplierExternalAccount, "If checked, upon manually allocating a payment to a customer or a supplier CashManager will prompt you to choose between Standard and Prepayment account, which can then be exported by the External Data Connector to your external system.")}
                                        {doExportStatementToFile && editFormControls.checkboxControl("autoConfirmAndExportHighTrustAllocations", autoConfirmAndExportHighTrustAllocationsCaption, this.onChange, this.state.autoConfirmAndExportHighTrustAllocations, "If checked, automatic allocations made with criteria with 'High' trust level will be automatically exported by the External Data Connector to your external system (e.g. an ERP).")}

                                        {doExportStatementToFile && editFormControls.checkboxControl("allocateToSupplierInvoiceUnpaidAmounts", "Allocate to supplier invoice unpaid amounts", this.onChange, this.state.allocateToSupplierInvoiceUnpaidAmounts, "Recommended settings: OFF if CashManager is used as a standalone system, ON if it is used as a payment interface for an external system. Determines how unallocated amounts of supplier invoices are calculated (and, respectively, which supplier invoices are allowed to be directly allocated to outgoing payments). If OFF, unallocated amounts are calculated as 'Paid amount outside CashManager' minus the allocations made in CashManager. If ON, unallocated amounts are calculated as 'Invoice amount' minus 'Paid amount outside CashManager' minus the allocations made in CashManager.")}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.state.error)}

                        {editFormControls.warningAlertWithLink(this.state.warningText, this.state.warningLink, this.state.warningLinkText)}

                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Company settings")}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && editForm}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ companySettings, country, currency, vatRate }) => ({
    companySettings: companySettings.companySettings,
    countries: country.countries,
    currencies: currency.currencies,
    error: companySettings.error,
    loadingCompanySettings: companySettings.loading,
    loadingCountries: country.loading,
    loadingCurrencies: currency.loading,
    loadingVATRates: vatRate.loading,
    saveSuccess: companySettings.saveSuccess,
    saving: companySettings.saving,
    vatRates: vatRate.vatRates
})

const mapDispatchToProps = dispatch => ({
    onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
    onGetCountries: () => dispatch(actionsCountry.countryGetAll()),
    onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
    onGetVATRates: () => dispatch(actionsVATRate.vatRateGetAll()),
    onCreateCompanySettings: (companySettings, history) => dispatch(actionsCompanySettings.companySettingsCreate(companySettings, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySettingsEdit);
