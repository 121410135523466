export const PaymentStatus = [
	{ code: 0, id: "PREPARED", description: "Prepared" }, 
	{ code: 8, id: "SENT_AS_FILE_TO_BANK", description: "Sent as file to bank" }, 
	{ code: 9, id: "SENT_FOR_SIGNING_IN_BANK", description: "Sent for signing in bank" }, 
	{ code: 10, id: "SENT_FOR_SIGNING_IN_CRM", description: "Sent for signing" }, 
	{ code: 11, id: "SIGNED_IN_CRM_PARTIALLY", description: "Signed partially" }, 
	{ code: 13, id: "SIGNED_IN_CRM_FULLY", description: "Signed fully" }, 
	{ code: 15, id: "SIGNED_IN_CRM_AND_SENT_TO_BANK", description: "Signed and sent to bank" }, 
	{ code: 20, id: "CONFIRMED_IN_BANK", description: "Confirmed in bank" }, 
	{ code: 21, id: "REJECTED_IN_BANK", description: "Rejected in bank" }, 
	{ code: 22, id: "MODIFIED_IN_BANK", description: "Modified in bank" }, 
	{ code: 23, id: "ERROR_SENDING_TO_BANK", description: "Error sending to bank" }, 
	{ code: 24, id: "PARTIALLY_REJECTED_IN_BANK", description: "Partially rejected in bank" }, 
	{ code: 25, id: "PARTIALLY_CONFIRMED_IN_BANK", description: "Partially confirmed in bank" }, 
	{ code: 30, id: "SENT_TO_ERP", description: "Sent to ERP" }, 
	{ code: 40, id: "CONFIRMED_IN_ERP", description: "Confirmed in ERP" }, 
];

export const PaymentStatusAsSelectFilterOptions = {
	"PREPARED": "Prepared",
	"SENT_AS_FILE_TO_BANK": "Sent as file to bank",
	"SENT_FOR_SIGNING_IN_BANK": "Sent for signing in bank",
	"SENT_FOR_SIGNING_IN_CRM": "Sent for signing",
	"SIGNED_IN_CRM_PARTIALLY": "Signed partially",
	"SIGNED_IN_CRM_FULLY": "Signed fully",
	"SIGNED_IN_CRM_AND_SENT_TO_BANK": "Signed and sent to bank",
	"CONFIRMED_IN_BANK": "Confirmed in bank",
	"REJECTED_IN_BANK": "Rejected in bank",
	"MODIFIED_IN_BANK": "Modified in bank",
	"ERROR_SENDING_TO_BANK": "Error sending to bank",
	"PARTIALLY_REJECTED_IN_BANK": "Partially rejected in bank",
	"PARTIALLY_CONFIRMED_IN_BANK": "Partially confirmed in bank",
	"SENT_TO_ERP": "Sent to ERP",
	"CONFIRMED_IN_ERP": "Confirmed in ERP",
};

export const formatPaymentStatusColor = (paymentStatus) => {
	switch (paymentStatus) {
		case "SIGNED_IN_CRM_AND_SENT_TO_BANK":
		case "PARTIALLY_CONFIRMED_IN_BANK":
			return "warning";
		case "ERROR_SENDING_TO_BANK":
		case "REJECTED_IN_BANK":
		case "PARTIALLY_REJECTED_IN_BANK":
			return "danger";
		case "CONFIRMED_IN_BANK":
		case "CONFIRMED_IN_ERP":
			return "success";
		default:
			return "secondary";
	}
}