import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	errorLines: null,
	exportFile: null,
	deleting: false,
	loading: false,
	loadingLines: false,
	loadingTotal: false,
	payments: null,
	paymentLines: null,
	resetting: false,
	saving: false,
	signing: false,
	total: null,
}

export default function PaymentReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.PAYMENT_GET_ALL:
		case actionTypes.PAYMENT_GET_SENT_TO_BANK_SIGNED:
		case actionTypes.PAYMENT_GET_SENT_TO_BANK_UNSIGNED:
		case actionTypes.PAYMENT_GET_SIGNED:
		case actionTypes.PAYMENT_GET_UNSIGNED:
			return {
				...state,
				total: null,
				error: null
			}
		case actionTypes.PAYMENT_GET_BY_ID:
			return {
				...state,
				error: null
			}
		case actionTypes.PAYMENT_GET_IN_PROCESS:
			return {
				...state,
				total: null,
				loading: true
			}
		case actionTypes.PAYMENT_GET_SUCCESS:
			return {
				...state,
				loading: false,
				payments: action.payments
			}
		case actionTypes.PAYMENT_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.PAYMENT_GET_TOTAL:
			return {
				...state,
				loadingTotal: true,
				total: null,
				error: null
			}
		case actionTypes.PAYMENT_GET_TOTAL_SUCCESS:
			return {
				...state,
				loadingTotal: false,
				total: action.total
			}
		case actionTypes.PAYMENT_GET_TOTAL_ERROR:
			return {
				...state,
				loadingTotal: false,
				total: null,
				error: action.error
			}
		case actionTypes.PAYMENT_CREATE:
			return {
				...state,
				saving: true,
				error: null
			}
		case actionTypes.PAYMENT_CREATE_SUCCESS:
			return {
				...state,
				saving: false,
				error: null,
				payments: [action.payment],
				notCreatedPayments: null
			}
		case actionTypes.PAYMENT_CREATE_ERROR:
			return {
				...state,
				loading: false,
				saving: false,
				error: action.error
			}
		case actionTypes.PAYMENT_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.PAYMENT_DELETE_LIST:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.PAYMENT_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.PAYMENT_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null
			}
		case actionTypes.PAYMENT_SEND_TO_BANK_LIST:
			return {
				...state,
				saving: true,
				exportFile: null,
				error: null
			}
		case actionTypes.PAYMENT_SEND_TO_BANK_ERROR:
			return {
				...state,
				saving: false,
				exportFile: null,
				error: action.error
			}
		case actionTypes.PAYMENT_SEND_TO_BANK_SUCCESS:
			return {
				...state,
				exportFile: action.exportFile,
				saving: false,
				error: null
			}
		case actionTypes.PAYMENT_SIGN_GET_CERTIFICATE:
			return {
				...state,
				error: null,
				signing: true
			}
		case actionTypes.PAYMENT_SIGN_INIT:
			return state;
		case actionTypes.PAYMENT_SIGN_ERROR:
			return {
				...state,
				error: action.error,
				resetting: false,
				signing: false
			}
		case actionTypes.PAYMENT_SIGN_RESET:
			return {
				...state,
				error: null,
				resetting: true
			}
		case actionTypes.PAYMENT_SIGN_RESET_SUCCESS:
			return {
				...state,
				payments: action.payments,
				resetting: false
			}
		case actionTypes.PAYMENT_SIGN_SUCCESS:
			return {
				...state,
				error: null,
				payments: action.payments,
				signing: false
			}
		case actionTypes.PAYMENT_LINE_GET_ALL:
		case actionTypes.PAYMENT_LINE_GET_PREPARED:
			return {
				...state,
				errorLines: null,
				loadingLines: true
			}
		case actionTypes.PAYMENT_LINE_GET_SUCCESS:
			return {
				...state,
				loadingLines: false,
				paymentLines: action.paymentLines
			}
		case actionTypes.PAYMENT_LINE_GET_ERROR:
			return {
				...state,
				loadingLines: false,
				errorLines: action.error
			}
		default:
			return state;
	}
}