import * as actionTypes from "./actionTypes";

export const supplierInvoiceCreate = (supplierInvoice, history) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CREATE,
		supplierInvoice: supplierInvoice, 
		history: history
	}
}

export const supplierInvoiceCreateError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CREATE_ERROR,
		error: thisError
	}
}

export const supplierInvoiceCreateSuccess = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CREATE_SUCCESS
	}
}

export const supplierInvoiceDelete = (id, history) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_DELETE,
		id: id,
		history: history
	}
}

export const supplierInvoiceDeleteError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_DELETE_ERROR,
		error: thisError
	}
}

export const supplierInvoiceDeleteSuccess = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_DELETE_SUCCESS
	}
}

export const supplierInvoiceGetAll = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_ALL
	}
}

export const supplierInvoiceGetUnpaid = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_UNPAID
	}
}

export const supplierInvoiceGetUnallocated = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_UNALLOCATED
	}
}

export const supplierInvoiceGetById = (id) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_BY_ID,
		id: id
	}
}

export const supplierInvoiceGetError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_ERROR,
		error: thisError
	}
}

export const supplierInvoiceGetSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_SUCCESS,
		supplierInvoices: data
	}
}

export const supplierInvoiceGetTotals = (selectedInvoices, bankAccountId, currencyId, doGroupPayments, history) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_TOTALS,
		selectedInvoices: selectedInvoices, 
		bankAccountId: bankAccountId,
		currencyId: currencyId,
		doGroupPayments: doGroupPayments,
		history: history
	}
}

export const supplierInvoiceGetTotalsError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_TOTALS_ERROR,
		error: thisError
	}
}

export const supplierInvoiceGetTotalsForUnallocated = (selectedInvoices, currencyCode, date) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_TOTALS_FOR_UNALLOCATED,
		selectedInvoices: selectedInvoices,
		currencyCode: currencyCode,
		date: date
	}
}

export const supplierInvoiceGetTotalsSuccess = (totals) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_GET_TOTALS_SUCCESS,
		totals: totals
	}
}


export const supplierInvoiceCandidateConvert = (idList, history) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_CONVERT,
		idList: idList,
		history: history
	}
}

export const supplierInvoiceCandidateConvertError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_CONVERT_ERROR,
		error: thisError
	}
}

export const supplierInvoiceCandidateConvertSuccess = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_CONVERT_SUCCESS
	}
}

export const supplierInvoiceCandidateDelete = (idList) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_DELETE,
		idList: idList
	}
}

export const supplierInvoiceCandidateDeleteError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_DELETE_ERROR,
		error: thisError
	}
}

export const supplierInvoiceCandidateDeleteSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_DELETE_SUCCESS,
		supplierInvoiceCandidates: data
	}
}

export const supplierInvoiceCandidateDownloadAttachment = (id) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_DOWNLOAD_ATTACHMENT,
		id: id
	}
}

export const supplierInvoiceCandidateDownloadAttachmentError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_DOWNLOAD_ATTACHMENT_ERROR,
		error: thisError
	}
}

export const supplierInvoiceCandidateDownloadAttachmentSuccess = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_DOWNLOAD_ATTACHMENT_SUCCESS
	}
}

export const supplierInvoiceCandidateGetAll = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_ALL
	}
}

export const supplierInvoiceCandidateGetById = (id) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_BY_ID,
		id: id
	}
}

export const supplierInvoiceCandidateGetError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_ERROR,
		error: thisError
	}
}

export const supplierInvoiceCandidateGetSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_SUCCESS,
		supplierInvoiceCandidates: data
	}
}

export const supplierInvoiceCandidateMatch = () => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_MATCH
	}
}

export const supplierInvoiceCandidateMatchError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_MATCH_ERROR,
		error: thisError
	}
}

export const supplierInvoiceCandidateMatchSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_MATCH_SUCCESS,
		data: data
	}
}

export const supplierInvoiceCandidateUpdate = (supplierInvoiceCandidate) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_UPDATE,
		supplierInvoiceCandidate: supplierInvoiceCandidate, 
	}
}

export const supplierInvoiceCandidateUpdateSupplier = (supplierInvoiceCandidate) => {
	return {
		type: actionTypes.SUPPLIER_INVOICE_CANDIDATE_UPDATE_SUPPLIER,
		supplierInvoiceCandidate: supplierInvoiceCandidate, 
	}
}

