import React, { Component } from "react";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";

import * as dateUtils from "../../helpers/dateAndTimeUtils";
import EditTask from "components/Pages/EditTask";
import * as formatUtils from "../../helpers/formatUtils";
import * as taskHelper from "../../helpers/taskHelper";

class TaskList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			activeTab: 1,
			tasksDueToday: [],
			tasksDueThisWeek: [],
			tasksDueThisMonth: [],
			hoveredTag: null,
			selectedTask: null,
			showEditTaskForm: false
		}
		this.toggleTab = this.toggleTab.bind(this)
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			})
		}
	}

	taskStatusColor = (status) => {
		switch (status) {
			case "COMPLETED":
				return "success";
			case "DEFERRED":
				return "primary";
			case "CANCELED":
				return "secondary";
			default:
				return "";
		}
	}

	taskStatusText = (status) => {
		switch (status) {
			case "COMPLETED":
				return "Completed";
			case "DEFERRED":
				return "Deferred";
			case "CANCELED":
				return "Canceled";
			default:
				return "";
		}
	}

	taskItem = (task) => {
		const overdueMarker =
			<React.Fragment>
				<span
					className={"badge badge-soft-warning font-size-12"}
				>
					Overdue
				</span>
				<span>
					&nbsp;
					&nbsp;
				</span>
			</React.Fragment>

		const statusMarker =
			<React.Fragment>
				<span
					className={"badge badge-soft-" + this.taskStatusColor(task.taskStatus) + " font-size-12"}
				>
					{this.taskStatusText(task.taskStatus)}
				</span>
				<span>
					&nbsp;
					&nbsp;
				</span>
			</React.Fragment>

		const dueDateAsJSDate = new Date(Date.parse(task.dueDate));

		const tagItem = (tag) => {
			const tagTitleSimplified = tag.title && tag.title.includes("/") && this.state.hoveredTag !== tag.id ? tag.title.substring(tag.title.lastIndexOf("/") + 1) : tag.title;

			return (
				<React.Fragment key={tag.id}>
					<span
						className={"badge badge-soft-secondary font-size-12"}
						onMouseEnter={() => this.setState({ hoveredTag: tag.id })}
						onMouseLeave={() => this.setState({ hoveredTag: null })}
					>
						{tagTitleSimplified}
					</span>
					<span>
						&nbsp;
					</span>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment key={task.id}>
				<div
					style={{
						cursor: "pointer",
						overflow: "hidden",
						paddingBottom: "15px"
					}}
					onClick={() => this.setState({
						selectedTask: task,
						showEditTaskForm: true
					})}
				>
					<h5 className="text-truncate font-size-14 mb-1">
						{task.title}
					</h5>
					<span>
						&nbsp;
					</span>
					{task.tags.map(tag => tagItem(tag))}
				</div>
				<div>
					<p
						className="text-muted mb-0"
						style={{
							cursor: "pointer",
							textAlign: "right"
						}}
					>
						{task.taskStatus !== "ACTIVE" && statusMarker}
						{task.taskStatus === "ACTIVE" && dateUtils.compareAsDates(dueDateAsJSDate, new Date()) === -1 && overdueMarker}
						{task.dueDate && formatUtils.formatJSDate(dueDateAsJSDate)}
					</p>
				</div>
			</React.Fragment >
		);
	}

	convertTaskListToTaskItems = (taskList) =>
		taskList.map(task =>
			this.taskItem(task)
		);


	render() {

		const tasksSplitByDueDate = taskHelper.splitTasksPerDueDate(this.props.taskList);

		const taskItemsDueToday = this.convertTaskListToTaskItems(tasksSplitByDueDate.tasksDueToday);
		const taskItemsDueThisWeek = this.convertTaskListToTaskItems(tasksSplitByDueDate.tasksDueThisWeek);
		const taskItemsDueThisMonth = this.convertTaskListToTaskItems(tasksSplitByDueDate.tasksDueThisMonth);

		const tasksStarred = this.props.starredTaskList ? this.convertTaskListToTaskItems(this.props.starredTaskList) : [];
		const tasksRecent = this.props.recentTaskList ? this.convertTaskListToTaskItems(this.props.recentTaskList) : [];

		const EditTaskFormAsVar =
			<EditTask
				onClose={() => this.setState({ showEditTaskForm: false })}
				task={this.state.selectedTask}
			/>

		const navItem = (tabIndex, tabTitle) =>
			<NavItem>
				<NavLink
					className={classnames({
						active: this.state.activeTab === tabIndex
					})}
					onClick={() => {
						this.toggleTab(tabIndex)
					}}
				>
					{tabTitle}
				</NavLink>
			</NavItem>

		return (
			<React.Fragment>
				<Card>

					{/* EditTaskFormAsVar must be within any component such as Card; if it is within the outer React.Fragment the component won't be rendered correctly */}
					{this.state.showEditTaskForm ? EditTaskFormAsVar : null}

					<CardBody>
						<h4 className="card-title mb-4">Tasks</h4>

						<Nav pills className="bg-light rounded">
							{navItem(1, "Today")}
							{navItem(2, "This week")}
							{navItem(3, "This month")}
							{navItem(4, "Starred")}
							{navItem(5, "Recent")}
						</Nav>

						<div className="mt-4">
							<SimpleBar style={{ maxHeight: "250px" }}>
								<div style={{
									display: "grid",
									gridTemplateColumns: "1fr auto",
									width: "100%"
								}}>
									{this.state.activeTab === 1
										? taskItemsDueToday
										: this.state.activeTab === 2
											? taskItemsDueThisWeek
											: this.state.activeTab === 3
												? taskItemsDueThisMonth
												: this.state.activeTab === 4
													? tasksStarred
													: tasksRecent}
								</div>
							</SimpleBar>
						</div>
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

export default TaskList;