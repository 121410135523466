import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row } from "reactstrap";

import * as actions from '../../store/Allocation/actions';
import * as actionsCompanySettings from '../../store/CompanySettings/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsAllocation from '../../definitions/columns/allocation';
import * as columnsAllocatedBankCharge from '../../definitions/columns/allocation_bankCharge';
import * as columnsAllocatedCustomer from '../../definitions/columns/allocation_customer';
import * as columnsAllocatedCustomerInvoice from '../../definitions/columns/allocation_customerInvoice';
import * as columnsAllocatedPayment from '../../definitions/columns/allocation_payment';
import * as columnsAllocatedSupplier from '../../definitions/columns/allocation_supplier';
import * as columnsAllocatedSupplierInvoice from '../../definitions/columns/allocation_supplierInvoice';
import * as columnsBankStatementRecord from '../../definitions/columns/bankStatementRecord';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsBackendFilters from '../../definitions/endpoints/endpoints-backend-filters';
import SelectPayment from '../../components/Pages/SelectPayment';
import SelectSupplierInvoices from '../../components/Pages/SelectSupplierInvoices';
import SelectSupplier from '../../components/Pages/SelectSupplierForAllocation';
import SelectCustomerInvoices from '../../components/Pages/SelectCustomerInvoices';
import SelectCustomer from '../../components/Pages/SelectCustomer';
import OverlayWithSpinner from "components/Common/OverlayWithSpinner";

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class BankStatementReconcile extends Component {

	constructor(props) {
		super(props);
		this.state = {
			confirmAllocationsDropdownOpen: false,
			bankStatementId: null,
			file: null,
			showSelectPayment: false,
			showSelectSupplierInvoices: false,
			showSelectSuppliers: false,
			showSelectCustomerInvoices: false,
			showSelectCustomers: false,
			bankChargeControls: []
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		this.setState({
			bankStatementId: id
		});
		if (id) {
			this.props.onGetAllocations(id);
		}
		if (!this.props.companySettings) {
			this.props.onGetCompanySettings();
		}
	}

	handleSubmit = event => {
		event.preventDefault();

	}

	handleChange = event => {
		this.setState({
			file: event.target.files[0]
		});
	}

	commandButtons = (cell, row, rowIndex, formatExtraData) => {
		return (
			<React.Fragment>
				<Button
					size="sm"
					color="danger"
					hidden={row.bankStatementRecordStatus !== "NONE"}
					onClick={() => {
						if (window.confirm("Are you sure you want to delete this allocation?")) {
							const allocation = {
								bankStatementRecordId: formatExtraData.bankStatementRecordId,
								paymentBulkId: (formatExtraData.type === "payment" ? row.id : null),
								supplierId: (formatExtraData.type === "supplier" ? row.id : null),
								supplierInvoiceIds: (formatExtraData.type === "supplierInvoice" ? [ row.id ] : null),
								customerId: (formatExtraData.type === "customer" ? row.id : null),
								customerInvoiceIds: (formatExtraData.type === "customerInvoice" ? [ row.id ] : null),
								removeBankCharge: (formatExtraData.type === "bankCharge")
							}
							this.props.onDeleteAllocation(allocation);
						}
					}}
				>
					<i className="bx bx-x" />
				</Button>
			</React.Fragment>
		);
	};

	launchAllocateToPayments = (row) => {
		this.props.onSetAllocationRecord(row);
		this.setState({ showSelectPayment: true });
	}

	launchAllocateToSupplierInvoices = (row) => {
		this.props.onSetAllocationRecord(row);
		this.setState({ showSelectSupplierInvoices: true });
	}

	launchAllocateToSuppliers = (row) => {
		this.props.onSetAllocationRecord(row);
		this.setState({ showSelectSuppliers: true });
	}

	launchAllocateToCustomerInvoices = (row) => {
		this.props.onSetAllocationRecord(row);
		this.setState({ showSelectCustomerInvoices: true });
	}

	launchAllocateToCustomers = (row) => {
		this.props.onSetAllocationRecord(row);
		this.setState({ showSelectCustomers: true });
	}

	applyBankChargeControl = (thisBankChargeControl) => {
		const bankChargeControls = this.state.bankChargeControls
			.filter(bankChargeControl => bankChargeControl.id !== thisBankChargeControl.id)
			.concat(thisBankChargeControl);

		this.setState({
			bankChargeControls: bankChargeControls,
		})
	}

	getBankChargeControlById = (id) => {
		return this.state.bankChargeControls.filter(bankChargeControl => bankChargeControl.id == id)[0];
	}

	launchAllocateAsBankCharge = (row) => {
		const thisBankChargeControl = this.getBankChargeControlById(row.id);
		thisBankChargeControl.show = !thisBankChargeControl.show;
		this.applyBankChargeControl(thisBankChargeControl);
	}

	onChangeBankChargeValue = (id, amount) => {
		const thisBankChargeControl = this.getBankChargeControlById(id);
		thisBankChargeControl.amount = amount;
		this.applyBankChargeControl(thisBankChargeControl);
	}

	onAddBankCharge = (id) => {
		const thisBankChargeControl = this.getBankChargeControlById(id);
		if (thisBankChargeControl.amount > 0) {
			if (!window.confirm("Bank charge amount must be negative. Continue anyway?")) {
				return;
			}
		}
		this.props.onCreateAllocation({
			bankStatementRecordId: id,
			bankChargeAmount: thisBankChargeControl.amount
		});
		this.applyBankChargeControl(thisBankChargeControl);
	}

	onConfirmAllocations = () => {
		if (window.confirm("Confirm all allocations?")) {
			this.props.onConfirmAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_CONFIRM_ALL);
		}
	}

	onConfirmAllocations_Charges = () => {
		if (window.confirm("Confirm all bank charges?")) {
			this.props.onConfirmAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_CONFIRM_CHARGES);
		}
	}

	onConfirmAllocations_Incoming = () => {
		if (window.confirm("Confirm all incoming payments?")) {
			this.props.onConfirmAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_CONFIRM_INCOMING);
		}
	}

	onConfirmAllocations_IncomingAndCharges = () => {
		if (window.confirm("Confirm all incoming payments and bank charges?")) {
			this.props.onConfirmAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_CONFIRM_INCOMING_AND_CHARGES);
		}
	}

	onConfirmAllocations_Outgoing = () => {
		if (window.confirm("Confirm all outgoing payments?")) {
			this.props.onConfirmAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_CONFIRM_OUTGOING);
		}
	}

	onConfirmAllocations_OutgoingAndCharges = () => {
		if (window.confirm("Confirm all outgoing payments and bank charges?")) {
			this.props.onConfirmAllocations(this.state.bankStatementId, endpointsBackendFilters.ALLOCATIONS_CONFIRM_OUTGOING_AND_CHARGES);
		}
	}

	onAutoAllocate = () => {
		this.props.onAutoAllocate(this.state.bankStatementId, this.props.history);
	}

	onRevertAllAllocations = () => {
		const exportedToERPAllocations = this.props.bankStatementRecords.filter(bankStatementRecord => bankStatementRecord.status === "EXPORTED");
		let warningText;
		if (exportedToERPAllocations.length) {
			warningText = "Some of the bank statement records have already been exported, are you sure you want to revert their status?";
		} else {
			warningText = "Revert all allocations?";
		}
		if (window.confirm(warningText)) {
			this.props.onRevertAllAllocations(this.state.bankStatementId);
		}
	}

	onRevertOneAllocation = (row) => {
		if (row.status === "EXPORTED") {
			if (!window.confirm("This bank statement record has already been exported. Are you sure you want to revert its status?")) {
				return;
			}
		}
		const bankStatementRecordId = row.id;
		this.props.onRevertAllocation(bankStatementRecordId);

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.bankStatementRecords != this.props.bankStatementRecords) {
			const bankChargeControls = [];
			for (let i in this.props.bankStatementRecords) {
				bankChargeControls.push({
					id: this.props.bankStatementRecords[i].id,
					show: false,
					amount: 0
				});
				this.setState({
					bankChargeControls: bankChargeControls,
				})
			}
		}
	}

	onToggleConfirmAllocationsDropdown = () => {
		this.setState({
			confirmAllocationsDropdownOpen: !this.state.confirmAllocationsDropdownOpen
		});
	}

	render() {

		const SelectPaymentForm =
			<SelectPayment
				onClose={() => this.setState({ showSelectPayment: false })}
			/>

		const SelectSupplierInvoicesForm =
			<SelectSupplierInvoices
				onClose={() => this.setState({ showSelectSupplierInvoices: false })}
			/>

		const SelectSupplierForm =
			<SelectSupplier
				onClose={() => this.setState({ showSelectSuppliers: false })}
				alreadyUsedAdvanceNo={this.props.allocatedSuppliers && this.props.allocatedSuppliers.length ? this.props.allocatedSuppliers.map(allocation => allocation.advanceNo) : []}
			/>

		const SelectCustomerInvoicesForm =
			<SelectCustomerInvoices
				onClose={() => this.setState({ showSelectCustomerInvoices: false })}
			/>

		const SelectCustomerForm =
			<SelectCustomer
				onClose={() => this.setState({ showSelectCustomers: false })}
				alreadyUsedAdvanceNo={this.props.allocatedCustomers && this.props.allocatedCustomers.length ? this.props.allocatedCustomers.map(allocation => allocation.advanceNo) : []}
			/>

		const pageTitle = "Bank Statements | " + config.AppName;
		const breadcrumbsTitle = "Bank statements";
		const breadcrumbsItem = "Reconcile bank statement";

		const columns = [
			columnsBankStatementRecord.criterionTrustLevel,
			columnsBankStatementRecord.debitCredit,
			columnsBankStatementRecord.beneficiaryName,
			columnsBankStatementRecord.beneficiaryRegNo,
			columnsBankStatementRecord.currencyCode,
			columnsBankStatementRecord.amount,
			columnsAllocation.unallocatedAmount,
			columnsBankStatementRecord.paymentDate,
			columnsBankStatementRecord.status
			// ,columnsBankStatementRecord.externalId
		];

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				//this.props.history.push(endpointsFrontend.BANK_STATEMENT_EDIT.replace(":id", row.id));
			}
		};

		const allocatedCustomerInvoiceTableColumns = [
			columnsAllocatedCustomerInvoice.criterionId,
			columnsAllocatedCustomerInvoice.customerName,
			columnsAllocatedCustomerInvoice.invoiceNo,
			columnsAllocatedCustomerInvoice.currencyCode,
			columnsAllocatedCustomerInvoice.amountWithVAT,
			columnsAllocatedCustomerInvoice.allocatedAmountInfo
		];

		// 2024.06.04: Temporary solution for showing invoiceNoAlt for Auto Kada and others.
		// Not set up for supplier invoices just yet.
		// In future, need to set up columns on CompanySettings level.
		for (let i in this.props.allocatedCustomerInvoices) {
			const thisInvoice = this.props.allocatedCustomerInvoices[i];
			if (thisInvoice.invoiceNoAlt && thisInvoice.invoiceNoAlt.length) {
				allocatedCustomerInvoiceTableColumns.push(columnsAllocatedCustomerInvoice.invoiceNoAlt)
				break;
			}
		}

		const expandRow = {
			renderer: (row, rowIndex) => {

				const allocatedSupplierTableColumns = [
					columnsAllocatedSupplier.criterionId,
					columnsAllocatedSupplier.name
				];
				if (this.props.companySettings.useAdvanceNo) {
					allocatedSupplierTableColumns.push(columnsAllocatedSupplier.advanceNo);
				}
				allocatedSupplierTableColumns.push(columnsAllocatedSupplier.allocatedAmountInfo);
				allocatedSupplierTableColumns.push({
					dataField: "commandButtons",
					text: "",
					formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "supplier" })
				});

				const allocatedCustomerTableColumns = [
					columnsAllocatedCustomer.criterionId,
					columnsAllocatedCustomer.name
				];
				if (this.props.companySettings.useAdvanceNo) {
					allocatedCustomerTableColumns.push(columnsAllocatedCustomer.advanceNo);
				}
				if (this.props.companySettings.useCustomerSupplierExternalAccount) {
					allocatedCustomerTableColumns.push(columnsAllocatedCustomer.externalAccountType);
				}
				allocatedCustomerTableColumns.push(columnsAllocatedCustomer.allocatedAmountInfo);
				allocatedCustomerTableColumns.push({
					dataField: "commandButtons",
					text: "",
					formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "customer" })
				});

				const allocatedPayments = this.props.allocatedPayments.filter(payment => payment.bankStatementRecordId == row.id);
				const allocatedSupplierInvoices = this.props.allocatedSupplierInvoices.filter(invoice => invoice.bankStatementRecordId == row.id);
				const allocatedSuppliers = this.props.allocatedSuppliers.filter(supplier => supplier.bankStatementRecordId == row.id);
				const allocatedCustomerInvoices = this.props.allocatedCustomerInvoices.filter(invoice => invoice.bankStatementRecordId == row.id);
				const allocatedCustomers = this.props.allocatedCustomers.filter(invoice => invoice.bankStatementRecordId == row.id);
				const allocatedBankCharges = this.props.allocatedBankCharges.filter(bankCharge => bankCharge.bankStatementRecordId == row.id);

				const bankStatementRecordId = row.id;

				const allocatedPaymentTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id'
							data={allocatedPayments}
							columns={[
								columnsAllocatedPayment.criterionId,
								columnsAllocatedPayment.supplierName,
								columnsAllocatedPayment.paymentDetails,
								columnsAllocatedPayment.currencyCode,
								columnsAllocatedPayment.totalAmountToPay,
								columnsAllocatedPayment.allocatedAmountInfo,
								{
									dataField: "commandButtons",
									text: "",
									formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "payment" })
								}
							]}
							condensed
						/>
					</React.Fragment>
				);

				const allocatedSupplierInvoiceTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id'
							data={allocatedSupplierInvoices}
							columns={[
								columnsAllocatedSupplierInvoice.criterionId,
								columnsAllocatedSupplierInvoice.supplierName,
								columnsAllocatedSupplierInvoice.invoiceNo,
								columnsAllocatedSupplierInvoice.currencyCode,
								columnsAllocatedSupplierInvoice.invoiceAmount,
								columnsAllocatedSupplierInvoice.allocatedAmountInfo,
								{
									dataField: "commandButtons",
									text: "",
									formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "supplierInvoice" })
								}
							]}
							condensed
						/>
					</React.Fragment>
				);

				const allocatedSupplierTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id'
							data={allocatedSuppliers}
							columns={allocatedSupplierTableColumns}
							condensed
						/>
					</React.Fragment>
				);

				// If we push directly to allocatedCustomerInvoiceTableColumns, each row will have more and more "delete" buttons
				const allocatedCustomerInvoiceTableColumns2 = [...allocatedCustomerInvoiceTableColumns];
				allocatedCustomerInvoiceTableColumns2.push({
					dataField: "commandButtons",
					text: "",
					formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "customerInvoice" })
				})

				const allocatedCustomerInvoiceTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id'
							data={allocatedCustomerInvoices}
							columns={allocatedCustomerInvoiceTableColumns2}
							condensed
						/>
					</React.Fragment>
				);

				const allocatedCustomerTable = (
					<React.Fragment>
						<BootstrapTable
							keyField='id'
							data={allocatedCustomers}
							columns={allocatedCustomerTableColumns}
							condensed
						/>
					</React.Fragment>
				);

				const allocatedBankChargeTable = (
					<BootstrapTable
						keyField='id'
						data={allocatedBankCharges}
						columns={[
							columnsAllocatedBankCharge.criterionId,
							columnsAllocatedBankCharge.type,
							columnsAllocatedBankCharge.currencyCode,
							columnsAllocatedBankCharge.amount,
							{
								dataField: "commandButtons",
								text: "",
								formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, { bankStatementRecordId: bankStatementRecordId, type: "bankCharge" })
							}
						]}
						condensed
					/>
				);

				const allocateToPaymentButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateToPayments(row)}
						>
							Allocate to a payment
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateWithPaymentsControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0 || row.status !== "NONE" ?
							null :
							allocateToPaymentButton)}

						{allocatedPayments && allocatedPayments.length ? allocatedPaymentTable : null}
					</React.Fragment>

				const allocateToSupplierButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateToSuppliers(row)}
						>
							Allocate to a supplier
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateToSupplierInvoicesButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateToSupplierInvoices(row)}
						>
							Allocate to supplier invoices
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateWithSupplierInvoicesControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0 || row.status !== "NONE" ?
							null :
							allocateToSupplierInvoicesButton)}

						{allocatedSupplierInvoices && allocatedSupplierInvoices.length ? allocatedSupplierInvoiceTable : null}
					</React.Fragment>

				const allocateWithSupplierControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0 || row.status !== "NONE" ?
							null :
							allocateToSupplierButton)}

						{allocatedSuppliers && allocatedSuppliers.length ? allocatedSupplierTable : null}
					</React.Fragment>

				const allocateToCustomerButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateToCustomers(row)}
						>
							Allocate to a customer
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateToCustomerInvoicesButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateToCustomerInvoices(row)}
						>
							Allocate to customer invoices
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateWithCustomerControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0 || row.status !== "NONE" ?
							null :
							allocateToCustomerButton)}

						{allocatedCustomers && allocatedCustomers.length ? allocatedCustomerTable : null}
					</React.Fragment>

				const allocateWithCustomerInvoicesControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0 || row.status !== "NONE" ?
							null :
							allocateToCustomerInvoicesButton)}

						{allocatedCustomerInvoices && allocatedCustomerInvoices.length ? allocatedCustomerInvoiceTable : null}
					</React.Fragment>

				const thisBankChargeControl = this.state.bankChargeControls.filter(bankChargeControl => bankChargeControl.id == row.id)[0];
				const thisBankChargeControlHTMLID = "bankChangeControl" + thisBankChargeControl.id;
				const addBankChargeButton =
					<React.Fragment>
						<Button
							onClick={() => this.launchAllocateAsBankCharge(row)}
						>
							Add bank charge
						</Button>
						<br />
						<br />
					</React.Fragment>

				const allocateAsBankChargeControls =
					<React.Fragment>
						{(row.unallocatedAmount == 0) || (row.status !== "NONE") || (allocatedBankCharges && allocatedBankCharges.length) ?
							null :
							addBankChargeButton}

						{thisBankChargeControl.show ? (
							<FormGroup className="mb-4" row>
								<Label
									htmlFor={thisBankChargeControlHTMLID}
									className="col-form-label col-lg-2"
								>
									Charge amount (negative)
								</Label>
								<Col lg="3">
									<Input
										id={thisBankChargeControlHTMLID}
										name={thisBankChargeControlHTMLID}
										type="number"
										step="0.01"
										className="form-control"
										placeholder="Enter bank charge amount"
										onChange={(e) => this.onChangeBankChargeValue(thisBankChargeControl.id, e.target.value)}
										value={thisBankChargeControl.amount}
									/>
								</Col>
								<Col>
									<Button
										onClick={(e) => this.onAddBankCharge(thisBankChargeControl.id, e.target.value)}
										disabled={(thisBankChargeControl.amount == 0)}
									>
										Add
									</Button>
								</Col>
							</FormGroup>
						) : null}

						{allocatedBankCharges && allocatedBankCharges.length ? allocatedBankChargeTable : null}
					</React.Fragment>

				const revertStatusControls =
					<React.Fragment>
						<Button
							color="danger"
							onClick={() => this.onRevertOneAllocation(row)}
						>
							Revert status
						</Button>
					</React.Fragment>

				return (
					<div className="react-bootstrap-table">
						<table className="table">
							<tbody style={{ borderColor: "#FFFFFF" }}>
								<tr>
									<td style={{ border: "0px transparent" }}></td>
									<td style={{ border: "0px transparent" }}>
										<div
											style={{
												position: "relative"
											}}
										>
											<OverlayWithSpinner
												show={this.props.updatingAllocations}
											/>

											{editFormControls.staticTextAreaControlWithoutLabel("paymentDetails", row.paymentDetails)}
											<br />

											{row.debitCredit === "D" ? allocateWithPaymentsControls : null}
											{row.debitCredit === "D" ? allocateWithSupplierInvoicesControls : null}
											{row.debitCredit === "D" ? allocateWithSupplierControls : null}
											{row.debitCredit === "C" ? allocateWithCustomerInvoicesControls : null}
											{row.debitCredit === "C" ? allocateWithCustomerControls : null}
											{/*row.debitCredit === "C" ?*/ allocateAsBankChargeControls/* : null*/}
											{row.status == "NONE" ? null : revertStatusControls}

										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				)
			},
			showExpandColumn: true,
			//expandByColumnOnly: true,
			expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
				expanded ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
			),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => (
				isAnyExpands ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
			)
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.bankStatementRecords}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					expandRow={expandRow}
					filter={filterFactory()}
				/>
			</div>

		let confirmedBankStatementRecords = [];
		let notConfirmedBankStatementRecords = [];
		if (this.props.bankStatementRecords) {
			confirmedBankStatementRecords = this.props.bankStatementRecords.filter(bankStatementRecord => bankStatementRecord.status !== "NONE");
			notConfirmedBankStatementRecords = this.props.bankStatementRecords.filter(bankStatementRecord => bankStatementRecord.status === "NONE");
		}
		const showConfirmAllocationsButton = (this.state.bankStatementId && notConfirmedBankStatementRecords.length);
		const showRevertAllocationsButton = (this.state.bankStatementId && confirmedBankStatementRecords.length);
		const confirmAllocationsButton =
			<Button
				color="primary"
				onClick={this.onConfirmAllocations}
			>
				Confirm allocations
				{" "}
				{this.props.confirmingAllocations ? editFormControls.buttonSpinner() : null}
			</Button>

		const confirmAllocationsDropdownItem_Charges = <DropdownItem onClick={this.onConfirmAllocations_Charges}>Bank charges</DropdownItem>
		const confirmAllocationsDropdownItem_Outgoing = <DropdownItem onClick={this.onConfirmAllocations_Outgoing}>Outgoing payments</DropdownItem>
		const confirmAllocationsDropdownItem_OutgoingAndCharges = <DropdownItem onClick={this.onConfirmAllocations_OutgoingAndCharges}>Outgoing payments and bank charges</DropdownItem>
		const confirmAllocationsDropdownItem_Incoming = <DropdownItem onClick={this.onConfirmAllocations_Incoming}>Incoming payments</DropdownItem>
		const confirmAllocationsDropdownItem_IncomingAndCharges = <DropdownItem onClick={this.onConfirmAllocations_IncomingAndCharges}>Incoming payments and bank charges</DropdownItem>

		let hasStatementExportTemplate_Outgoing, hasStatementExportTemplate_OutgoingAndCharges, hasStatementExportTemplate_Incoming, hasStatementExportTemplate_IncomingAndCharges, hasStatementExportTemplate_Charges;
		if (this.props.companySettings) {
			hasStatementExportTemplate_Outgoing = this.props.companySettings.statementExportTemplate_Outgoing && this.props.companySettings.statementExportTemplate_Outgoing.length
			hasStatementExportTemplate_OutgoingAndCharges = this.props.companySettings.statementExportTemplate_OutgoingAndCharges && this.props.companySettings.statementExportTemplate_OutgoingAndCharges.length
			hasStatementExportTemplate_Incoming = this.props.companySettings.statementExportTemplate_Incoming && this.props.companySettings.statementExportTemplate_Incoming.length;
			hasStatementExportTemplate_IncomingAndCharges = this.props.companySettings.statementExportTemplate_IncomingAndCharges && this.props.companySettings.statementExportTemplate_IncomingAndCharges.length;
			hasStatementExportTemplate_Charges = this.props.companySettings.statementExportTemplate_Charges && this.props.companySettings.statementExportTemplate_Charges.length;
		}

		const confirmAllocationsDropdown =
			<Dropdown isOpen={this.state.confirmAllocationsDropdownOpen} toggle={this.onToggleConfirmAllocationsDropdown}>
				<DropdownToggle
					color="primary"
					caret
				>
					Confirm allocations...
				</DropdownToggle>
				<DropdownMenu>
					{hasStatementExportTemplate_Charges ? confirmAllocationsDropdownItem_Charges : null}
					{hasStatementExportTemplate_Incoming ? confirmAllocationsDropdownItem_Incoming : null}
					{hasStatementExportTemplate_IncomingAndCharges ? confirmAllocationsDropdownItem_IncomingAndCharges : null}
					{hasStatementExportTemplate_Outgoing ? confirmAllocationsDropdownItem_Outgoing : null}
					{hasStatementExportTemplate_OutgoingAndCharges ? confirmAllocationsDropdownItem_OutgoingAndCharges : null}
				</DropdownMenu>
			</Dropdown>

		const hasStatementExportTemplates = hasStatementExportTemplate_Charges || hasStatementExportTemplate_Incoming || hasStatementExportTemplate_IncomingAndCharges || hasStatementExportTemplate_Outgoing || hasStatementExportTemplate_OutgoingAndCharges;
		// const hasStatementExportTemplates = false;

		const confirmAllocationsControl = hasStatementExportTemplates && this.props.companySettings.exportStatementToFile == "MANUAL" ? confirmAllocationsDropdown : confirmAllocationsButton;

		const autoAllocationButton =
			<Button
				color="secondary"
				onClick={this.onAutoAllocate}
			>
				Automatically allocate
				{" "}
				{this.props.allocating ? editFormControls.buttonSpinner() : null}
			</Button>

		const revertAllocationsButton =
			<Button
				color="danger"
				onClick={this.onRevertAllAllocations}
			>
				Revert allocations
				{" "}
				{this.props.revertingAllocations ? editFormControls.buttonSpinner() : null}
			</Button>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>

					{this.state.showSelectPayment && SelectPaymentForm}
					{this.state.showSelectSupplierInvoices && SelectSupplierInvoicesForm}
					{this.state.showSelectSuppliers && SelectSupplierForm}
					{this.state.showSelectCustomerInvoices && SelectCustomerInvoicesForm}
					{this.state.showSelectCustomers && SelectCustomerForm}

					<Container fluid>

						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.bankStatementRecords ?
											table
											: null}

										<div style={{ display: 'flex' }}>

											{showConfirmAllocationsButton ? confirmAllocationsControl : null}

											&nbsp;

											{showConfirmAllocationsButton ? autoAllocationButton : null}

											&nbsp;

											{showRevertAllocationsButton ? revertAllocationsButton : null}

										</div>

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ allocation, companySettings: { companySettings } }) => ({
	confirmingAllocations: allocation.confirmingAllocations,
	allocatedPayments: allocation.allocatedPayments,
	allocatedSupplierInvoices: allocation.allocatedSupplierInvoices,
	allocatedSuppliers: allocation.allocatedSuppliers,
	allocatedCustomerInvoices: allocation.allocatedCustomerInvoices,
	allocatedCustomers: allocation.allocatedCustomers,
	allocatedBankCharges: allocation.allocatedBankCharges,
	allocating: allocation.allocating,
	bankStatementRecord: allocation.bankStatementRecord,
	bankStatementRecords: allocation.bankStatementRecords,
	companySettings: companySettings,
	error: allocation.error,
	loading: allocation.loading || !companySettings || companySettings.loading,
	revertingAllocations: allocation.revertingAllocations,
	updatingAllocations: allocation.updatingAllocations
})

const mapDispatchToProps = dispatch => ({
	onAutoAllocate: (bankStatementId, history) => dispatch(actions.allocationAutoAllocate(bankStatementId, history)),
	onConfirmAllocations: (bankStatementId, filter) => dispatch(actions.allocationConfirm(bankStatementId, filter)),
	onGetAllocations: (id) => dispatch(actions.allocationGetAll(id)),
	onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
	onCreateAllocation: (allocation) => dispatch(actions.allocationCreate(allocation)),
	onDeleteAllocation: (allocation) => dispatch(actions.allocationDelete(allocation)),
	onRevertAllAllocations: (bankStatementId) => dispatch(actions.allocationRevertAll(bankStatementId)),
	onRevertAllocation: (bankStatementRecordId) => dispatch(actions.allocationRevert(bankStatementRecordId)),
	onSetAllocationRecord: (record) => dispatch(actions.allocationRecordSet(record))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(BankStatementReconcile));
