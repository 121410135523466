import React, { Component } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Label, Input, Row, Spinner } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from "react-redux";

import * as actions from 'store/Customer/actions';
import * as actionsAllocation from 'store/Allocation/actions';
import Backdrop from '../Common/Backdrop';
import * as browseFormControls from '../../helpers/browseFormControls';
import * as columnsCustomer from 'definitions/columns/customer';
import ColumnSelectionForm from "components/Pages/ColumnSelectionForm";
import * as editFormControls from 'helpers/editFormControls';
import * as formatUtils from 'helpers/formatUtils';
import * as selectRowUtils from 'helpers/selectRowUtils';
import * as tableUtils from '../../helpers/tableUtils';

import classes from './Pages.module.css';

const virtualPathname = "components\Pages\SelectCustomer";

class SelectCustomer extends Component {

    constructor(props) {
        super(props);
        this.filterByCustomerName = this.filterByCustomerName.bind(this);
        this.filterByCustomerRegNo = this.filterByCustomerRegNo.bind(this);
        this.filterByAmount = this.filterByAmount.bind(this);
        this.state = {
            selectedRows: [],
            advanceNo: "",
            externalAccountType: 1,

            columns: [],
            showColumnSelectionForm: false,
            advanceNoIsEmpty: false,
            advanceNoAlreadyUsed: false
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
        if (thisObjectName === "advanceNo") {
            this.setState({
                advanceNoIsEmpty: !thisValue || !thisValue.length,
                advanceNoAlreadyUsed: thisValue && thisValue.length && this.calculateAdvanceNoAlreadyUsed(thisValue)
            });
        }
    }

    componentDidMount() {
        this.props.onGetUnallocatedCustomers();
        const defaultColumnSetup = "";
        this.setState({
            columns: tableUtils.getSavedColumnSetup(columnsCustomer, virtualPathname, defaultColumnSetup)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    launchColumnSelectionForm = () => {
        this.setState({
            showColumnSelectionForm: true
        });
    }

    applySelectedColumns = (selectedColumns) => {
        tableUtils.setSavedColumnSetup(selectedColumns, virtualPathname);
        this.setState({
            columns: tableUtils.applySavedColumnSetup(columnsCustomer, selectedColumns)
        });
    }

    filterByCustomerName(value) {
        this.customerNameFilter(value);
    }

    filterByCustomerRegNo(value) {
        this.customerRegNoFilter(value);
    }

    filterByAmount(value) {
        this.amountFilter(value);
    }

    calculateAdvanceNoAlreadyUsed(advanceNo) {
        if (this.props.alreadyUsedAdvanceNo && this.props.alreadyUsedAdvanceNo.length) {
            for (let i in this.props.alreadyUsedAdvanceNo) {
                if (advanceNo === this.props.alreadyUsedAdvanceNo[i]) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    onCreateAllocation = () => {
        if (this.props.companySettings.useAdvanceNo && (!this.state.advanceNo.length || this.calculateAdvanceNoAlreadyUsed(this.state.advanceNo))) {
            const advanceNoInput = document.getElementById("advanceNo");
            if (advanceNoInput) {
                advanceNoInput.focus();
            }
            this.setState({
                advanceNoIsEmpty: !this.state.advanceNo.length,
                advanceNoAlreadyUsed: this.calculateAdvanceNoAlreadyUsed(this.state.advanceNo)
            });
            return;
        }
        const customers = [];
        this.state.selectedRows.map(selectedRow => {
            customers.push({
                id: selectedRow
            })
        })
        const allocation = {
            bankStatementRecordId: this.props.allocationRecord.id,
            customers: customers,
            advanceNo: this.state.advanceNo,
            externalAccountType: this.props.companySettings.exportStatementToFile ? this.state.externalAccountType : 0
        }
        this.props.onCreateAllocation(allocation);
        this.props.onClose();
    }

    render() {

        const loading = this.props.loading;

        const ColumnSelectionFormWithProps =
            <ColumnSelectionForm
                availableColumns={[
                    columnsCustomer.registrationCountryCode,
                    columnsCustomer.externalId,
                ]}
                selectedColumns={this.state.columns}
                applySelectedColumns={(selectedColumns) => this.applySelectedColumns(selectedColumns)}
                onClose={() => this.setState({ showColumnSelectionForm: false })}
            />

        const allocationRecordForm =
            <React.Fragment>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryName"
                        className="col-form-label col-lg-2"
                    >
                        Beneficiary name
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryName"
                            name="beneficiaryName"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.beneficiaryName}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.customers}
                                onClick={() => this.filterByCustomerName(this.props.allocationRecord.beneficiaryName)}
                            >
                                Filter by this name
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryRegNo"
                        className="col-form-label col-lg-2"
                    >
                        Beneficiary reg. No.
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryRegNo"
                            name="beneficiaryRegNo"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.beneficiaryRegNo}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.customers}
                                onClick={() => this.filterByCustomerRegNo(this.props.allocationRecord.beneficiaryRegNo)}
                            >
                                Filter by this reg. No.
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="amount"
                        className="col-form-label col-lg-2"
                    >
                        Unallocated amount
                    </Label>
                    <Col lg="4">
                        <Input
                            id="amount"
                            name="amount"
                            type="text"
                            className="form-control-plaintext"
                            value={
                                formatUtils.formatAmountWithDCSign(this.props.allocationRecord.unallocatedAmount, this.props.allocationRecord.isDebit)
                                + " "
                                + this.props.allocationRecord.currencyCode
                            }
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="paymentDate"
                        className="col-form-label col-lg-2"
                    >
                        Payment date
                    </Label>
                    <Col lg="6">
                        <Input
                            id="paymentDate"
                            name="paymentDate"
                            type="text"
                            className="form-control-plaintext"
                            value={formatUtils.formatLocalDate(this.props.allocationRecord.paymentDate)}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="paymentDetails"
                        className="col-form-label col-lg-2"
                    >
                        Payment details
                    </Label>
                    <Col lg="6">
                        <Input
                            id="paymentDetails"
                            name="paymentDetails"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.paymentDetails}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                </FormGroup>
            </React.Fragment>

        const columns = [
            {
                ...columnsCustomer.name,
                filter: textFilter({
                    getFilter: (filter) => { this.customerNameFilter = filter; }
                }),
            },
            {
                ...columnsCustomer.regNo,
                filter: textFilter({
                    getFilter: (filter) => { this.customerRegNoFilter = filter; }
                }),
            },
            columnsCustomer.vatRegNo
        ];

        const keysColumnsCustomer = Object.keys(columnsCustomer);
        for (let i in keysColumnsCustomer) {
            const thisColumn = columnsCustomer[keysColumnsCustomer[i]];
            if (this.state.columns && this.state.columns.includes(thisColumn)) {
                columns.push(thisColumn);
            }
        }

        const selectRow = {
            mode: 'checkbox',

            onSelect: (row, isSelect, rowIndex, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
                this.setState({
                    selectedRows: selectedRows
                });
            },

            onSelectAll: (isSelect, rows, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect)
                this.setState({
                    selectedRows: selectedRows
                });
            }
        };

        const table =
            <React.Fragment>
                {browseFormControls.columnSelectionButton(this.launchColumnSelectionForm)}
                <div className="mt-3">
                    <BootstrapTable
                        keyField='id'
                        data={this.props.customers}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPageList: [5, 10, 25] })}
                        filter={filterFactory()}
                        selectRow={selectRow}
                        condensed
                    />
                </div>
            </React.Fragment>

        const advanceNoInput =
            <Col xs="auto">
                {editFormControls.textControlWithoutLabel("advanceNo", this.onChange, this.state.advanceNo, "Advance No.", this.state.advanceNoIsEmpty || this.state.advanceNoAlreadyUsed)}
            </Col>

        const externalAccountInput =
            <React.Fragment>
                <Col xs="auto">
                    <Input
                        name="externalAccountType"
                        type="radio"
                        onChange={this.onChange}
                        checked={this.state.externalAccountType == 1}
                        value={1}
                    />
                    &nbsp;
                    Standard account
                </Col>
                <Col xs="auto">
                    <Input
                        name="externalAccountType"
                        type="radio"
                        onChange={this.onChange}
                        checked={this.state.externalAccountType == 2}
                        value={2}
                    />
                    &nbsp;
                    Prepayment account
                </Col>
            </React.Fragment>

        const externalSystemControls =
            <React.Fragment>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    {this.props.companySettings.useAdvanceNo && advanceNoInput}
                    {this.props.companySettings.useCustomerSupplierExternalAccount && externalAccountInput}
                </Row>
                <br />
            </React.Fragment>

        return (

            <React.Fragment>
                {this.state.showColumnSelectionForm && ColumnSelectionFormWithProps}
                <Backdrop
                    show
                    onClick={this.props.onClose}
                >
                </Backdrop>
                <div
                    className={classes.PageCardContainer}
                >
                    <Card
                        // className={classes.PageCard} // Like in ColumnSelectionForm, for some reason specifying these parameters in CSS does not work 
                        style={{
                            height: "100%",
                            overflowX: "auto",
                            overflowY: "auto"
                        }}
                    >
                        {this.state.advanceNoAlreadyUsed && editFormControls.warningAlertWithLink("Advance No. " + this.state.advanceNo + " has already been used in this statement")}
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    Select a customer
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.props.onClose}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>
                            {this.props.allocationRecord ? allocationRecordForm : null}

                            {loading ? (
                                <div width="100%" height="100%" align="center">
                                    {editFormControls.bigSpinner()}
                                    <br /><br />
                                </div>
                            ) : null}

                            {!loading && this.props.customers ? table : null}

                            <Container fluid>
                                {(this.props.companySettings.useAdvanceNo || this.props.companySettings.useCustomerSupplierExternalAccount) && externalSystemControls}
                                <Row>
                                    <Col>
                                        <Button
                                            color="primary"
                                            onClick={this.onCreateAllocation}
                                            disabled={this.state.selectedRows.length == 0}
                                        >
                                            Allocate
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                            <br />
                            <br />
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ allocation, companySettings, customer }) => ({
    allocationRecord: allocation.allocationRecord,
    companySettings: companySettings.companySettings,
    error: customer.error,
    loading: customer.loading,
    loadingTotals: customer.loadingTotals,
    customers: customer.customers,
    totals: customer.totals,
});

const mapDispatchToProps = dispatch => ({
    onGetUnallocatedCustomers: () => dispatch(actions.customerGetAll()),
    onCreateAllocation: (allocation) => dispatch(actionsAllocation.allocationCreate(allocation))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectCustomer);