import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import { downloadHelper } from '../../helpers/downloadHelper';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

function* createSupplierInvoiceSaga({ supplierInvoice, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.SUPPLIER_INVOICE_CREATE_OR_UPDATE,
			supplierInvoice
		);
		if (supplierInvoice.paidAmountOutsideCRM == supplierInvoice.invoiceAmount) {
			history.push(endpointsFrontend.SUPPLIER_INVOICE_BROWSE_ALL);
		} else {
			history.push(endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID);
		}
		yield put(actions.supplierInvoiceCreateSuccess());
	} catch (e) {
		yield put(actions.supplierInvoiceCreateError(e));
	}
}

function* deleteSupplierInvoiceSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.SUPPLIER_INVOICE_GET_BY_ID.replace("{supplierInvoiceId}", id)
		);
		history.push(endpointsFrontend.SUPPLIER_INVOICE_BROWSE_ALL);
		yield put(actions.supplierInvoiceDeleteSuccess());
	} catch (e) {
		yield put(actions.supplierInvoiceDeleteError(e));
	}
}

function* getSupplierInvoicesSaga(url) {
	try {
		const response = yield call(
			rest.get,
			url
		);

		yield put(actions.supplierInvoiceGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.supplierInvoiceGetError(e));
	}
}

function* getAllSupplierInvoicesSaga() {
	yield call(getSupplierInvoicesSaga, endpointsBackend.SUPPLIER_INVOICE_GET_ALL);
}

function* getUnpaidSupplierInvoicesSaga() {
	yield call(getSupplierInvoicesSaga, endpointsBackend.SUPPLIER_INVOICE_GET_UNPAID);
}

function* getUnallocatedSupplierInvoicesSaga() {
	yield call(getSupplierInvoicesSaga, endpointsBackend.SUPPLIER_INVOICE_GET_UNALLOCATED);
}

function* getSupplierInvoiceByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_INVOICE_GET_BY_ID.replace("{supplierInvoiceId}", id)
		);

		yield put(actions.supplierInvoiceGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.supplierInvoiceGetError(e));
	}
}

function* getSupplierInvoiceTotalsSaga({ selectedInvoices, bankAccountId, currencyId, doGroupPayments, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.PAYMENT_GET_TOTAL_FROM_SIMULATED,
			{
				supplierInvoiceIds: selectedInvoices,
				bankAccountId: bankAccountId,
				currencyId: currencyId,
				doGroupPayments: doGroupPayments
			}
		);
		yield put(actions.supplierInvoiceGetTotalsSuccess(response.data));
	} catch (e) {
		yield put(actions.supplierInvoiceGetTotalsError(e));
	}
}

function* getSupplierInvoiceTotalsForUnallocatedSaga({ selectedInvoices, currencyCode, date }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.SUPPLIER_INVOICE_GET_UNALLOCATED_TOTALS,
			{
				idList: selectedInvoices,
				currencyCode: currencyCode,
				date: date
			}
		);
		yield put(actions.supplierInvoiceGetTotalsSuccess(response.data));
	} catch (e) {
		// Similarly in CustomerInvoice saga, as of 2024-12-18 error on getting totals is not processed further
	}
}

function* convertSupplierInvoiceCandidateSaga({ idList, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE_CONVERT,
			{ idList: idList }
		);
		yield put(actions.supplierInvoiceCandidateConvertSuccess());
		history.push(endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID);
	} catch (e) {
		yield put(actions.supplierInvoiceCandidateConvertError(e));
	}
}

function* updateSupplierInvoiceCandidateSaga({ supplierInvoiceCandidate }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE,
			supplierInvoiceCandidate
		);
		yield put(actions.supplierInvoiceCandidateMatchSuccess(response.data));
	} catch (e) {
		yield put(actions.supplierInvoiceCandidateMatchError(e));
	}
}

function* updateSupplierInvoiceCandidateSupplierSaga({ supplierInvoiceCandidate }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE_UPDATE_SUPPLIER,
			supplierInvoiceCandidate
		);
		yield put(actions.supplierInvoiceCandidateMatchSuccess(response.data));
	} catch (e) {
		yield put(actions.supplierInvoiceCandidateMatchError(e));
	}
}

function* deleteSupplierInvoiceCandidateSaga({ idList }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE,
			{ idList: idList }
		);
		yield put(actions.supplierInvoiceCandidateDeleteSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.supplierInvoiceCandidateDeleteError(e));
	}
}

function* getAllSupplierInvoiceCandidatesSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE_GET_ALL
		);
		yield put(actions.supplierInvoiceCandidateGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.supplierInvoiceCandidateGetError(e));
	}
}

function* getSupplierInvoiceCandidateByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE_GET_BY_ID.replace("{supplierInvoiceCandidateId}", id)
		);
		yield put(actions.supplierInvoiceCandidateGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.supplierInvoiceCandidateGetError(e));
	}
}

function* supplierInvoiceCandidateDownloadAttachmentSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE_DOWNLOAD_ATTACHMENT.replace("{supplierInvoiceCandidateId}", id)
		);
		yield call(downloadHelper, response);
		yield put(actions.supplierInvoiceCandidateDownloadAttachmentSuccess());
	} catch (e) {
		yield put(actions.supplierInvoiceCandidateDownloadAttachmentError(e));
	}
}

function* matchAllSupplierInvoiceCandidatesSaga({ }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE_REMATCH
		);
		yield put(actions.supplierInvoiceCandidateMatchSuccess(response.data));
	} catch (e) {
		yield put(actions.supplierInvoiceCandidateMatchError(e));
	}
}

function* supplierInvoiceSaga() {
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_ALL, getAllSupplierInvoicesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_UNPAID, getUnpaidSupplierInvoicesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_UNALLOCATED, getUnallocatedSupplierInvoicesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_BY_ID, getSupplierInvoiceByIdSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CREATE, createSupplierInvoiceSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_DELETE, deleteSupplierInvoiceSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_TOTALS, getSupplierInvoiceTotalsSaga))
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_GET_TOTALS_FOR_UNALLOCATED, getSupplierInvoiceTotalsForUnallocatedSaga))
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CANDIDATE_CONVERT, convertSupplierInvoiceCandidateSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CANDIDATE_DELETE, deleteSupplierInvoiceCandidateSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CANDIDATE_DOWNLOAD_ATTACHMENT, supplierInvoiceCandidateDownloadAttachmentSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_ALL, getAllSupplierInvoiceCandidatesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_BY_ID, getSupplierInvoiceCandidateByIdSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CANDIDATE_MATCH, matchAllSupplierInvoiceCandidatesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CANDIDATE_UPDATE, updateSupplierInvoiceCandidateSaga));
	yield (takeLatest(actionTypes.SUPPLIER_INVOICE_CANDIDATE_UPDATE_SUPPLIER, updateSupplierInvoiceCandidateSupplierSaga));
}

export default supplierInvoiceSaga;