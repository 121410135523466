import React from "react";
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";

import * as AllocationCriterionType from "../definitions/enums/AllocationCriterionType";
import * as CounterType from "../definitions/enums/CounterType";
import * as InvoiceDuplicationStatus from 'definitions/enums/InvoiceDuplicationStatus';
import * as MatchingStatus from 'definitions/enums/MatchingStatus';
import * as PaymentStatus from "../definitions/enums/PaymentStatus";
import * as ScheduledTaskStatus from 'definitions/enums/ScheduledTaskStatus';
import * as ScheduledTaskType from 'definitions/enums/ScheduledTaskType';
import * as SupplierBankAccountStatus from 'definitions/enums/SupplierBankAccountStatus';
import * as SupplierInvoiceCandidateStatus from 'definitions/enums/SupplierInvoiceCandidateStatus';

export const formatNumberWith2Digits = (number) => {
    return (number < 10 ? "0" : "") + number;
}

export const formatLocalDate = (localDate) => {
    return (localDate ?
        (localDate[0] + "-" + formatNumberWith2Digits(localDate[1]) + "-" + formatNumberWith2Digits(localDate[2])) :
        null);
}

export const formatLocalDateTime = (localDateTime) => {
    return (localDateTime ?
        (formatLocalDate(localDateTime) + " " + localDateTime[3] + ":" + formatNumberWith2Digits(localDateTime[4])) :
        null);
}

export const formatJSDate = (date) => {
    return (date ?
        (date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1) + "-" + ((date.getDate() < 10 ? "0" : "") + date.getDate())) :
        null);
}

export const formatJSDateTime = (datetime) => {
    return (datetime
        ? (datetime.getFullYear() + "-" + formatNumberWith2Digits(datetime.getMonth() + 1) + "-" + formatNumberWith2Digits(datetime.getDate())
            + " " + formatNumberWith2Digits(datetime.getHours()) + ":" + formatNumberWith2Digits(datetime.getMinutes()) + ":" + formatNumberWith2Digits(datetime.getSeconds()))
        : null);
}

export const formatNamedFieldsDatetimeObject = (datetime) => {
    return (datetime
        ? (datetime.year + "-" + formatNumberWith2Digits(datetime.month) + "-" + formatNumberWith2Digits(datetime.day)
            + " " + formatNumberWith2Digits(datetime.hour) + ":" + formatNumberWith2Digits(datetime.minute) + ":" + formatNumberWith2Digits(datetime.second))
        : null);
}

export const formatAmount = (amount) => {
    // return amount.toLocaleString('de-DE', { style: 'currency', currency: currencyCode }) 	// this also works but returns a currency symbol in the result
    return (amount || amount === 0) ? amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null;
}

export const formatAmountWithDCSign = (amount, isDebit) => {
    if (isDebit == null) {
        isDebit = (amount < 0);
    }
    if (amount < 0) {
        amount = -amount;
    }
    return (amount) ? (
        (isDebit ? "-" : "+")
        + amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    ) : null;
}

export const formatRatio = (ratio) => {
    return ratio.antecedent + " / " + ratio.total;
}

export const formatAmountRatio = (ratio) => {
    return formatAmount(ratio.antecedent) + " / " + formatAmount(ratio.total);
}

export const ratioValue = (ratio) => {
    return ratio.antecedent / ratio.total;
}

export const formatRatioAsProgressBar = (ratio, color, ratioFormat, customText) => {
    if (ratio) {
        return (
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%"          // If put inside <td>, needs <table> and <td> to also have height=100% (currently set in _table.scss in the .table-bordered class)
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        width: (ratio.total ? ratio.antecedent / ratio.total * 100 + "%" : "0%"),
                        height: "100%",     // If put inside <td>, needs <table> and <td> to also have height=100% (currently set in _table.scss in the .table-bordered class)
                        backgroundColor: color
                    }}
                >
                    &nbsp;
                </div>
                <div
                    style={{
                        position: "absolute",
                        width: "100%"
                    }}
                >
                    {customText ? (customText + " (") : ""}{ratioFormat == "AMOUNT" ? formatAmountRatio(ratio) : formatRatio(ratio)}{customText ? ")" : ""}
                </div>
            </div>
        );
    } else {
        return '';
    }
}

export const formatAmountRatioAsProgressBar = (ratio, color) => {
    return formatRatioAsProgressBar(ratio, color, "AMOUNT");
}

export const formatHours = (hours) => {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return wholeHours + ":" + (minutes < 10 ? "0" : "") + minutes;
}

export const addTimeToDatetime = (datetime) => {
    if (datetime && datetime.length) {
        if (datetime.trim().length === 10) {
            return datetime.trim() + " 00:00:00";
        } else {
            return datetime;
        }
    } else {
        return "";
    }
}

export const removeTimeFromDatetime = (datetime) => {
    if (datetime && datetime.length) {
        if (datetime.trim().length > 10) {
            return datetime.trim().substring(0, 10);
        } else {
            return datetime;
        }
    } else {
        return "";
    }
}

export const formatAllocationCriterionTrustLevelText = (criterionTrustLevel) => {
    switch (criterionTrustLevel) {
        case "BUILT_IN":
            return "Built-in";
        case "HIGH":
            return "High";
        case "MEDIUM":
            return "Medium";
        case "SUGGESTION":
            return "Suggestion";
        case "MANUAL":
            return "Manual";
        default:
            return "";
    }

}

export const formatAllocationCriterionTrustLevelColor = (criterionTrustLevel) => {
    switch (criterionTrustLevel) {
        case "BUILT_IN":
            return "success";
        case "HIGH":
            return "success";
        case "MEDIUM":
            return "warning";
        case "SUGGESTION":
            return "danger";
        default:
            return "secondary";
    }
}

export const formatAllocationCriterionTrustLevel = (criterionTrustLevel) => {
    const color = formatAllocationCriterionTrustLevelColor(criterionTrustLevel);
    const trustLevelText = formatAllocationCriterionTrustLevelText(criterionTrustLevel);
    return (
        <span
            className={"badge badge-soft-" + color + " font-size-12"}
            style={{ marginBottom: "0.3rem" }}
        >
            {trustLevelText}
        </span>
    );
}

export const getBeneficiaryCaption = (criterionType) => {
    switch (criterionType) {
        case "PAYMENT":
        case "SUPPLIER_INVOICE":
            return "Supplier";
        case "CUSTOMER_INVOICE":
            return "Customer";
        default:
            return "";
    }
}

export const formatAllocationCriterionDescriptionText = (criterion) => {
    // Make sure to mirror the changes in backend: ReportService.allocationCriterionDescription()
    const beneficiaryCaption = getBeneficiaryCaption(criterion.type);
    let criterionDescriptionText = "";
    if (criterion.hasAmount) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Amount";
    }
    if (criterion.hasAmountSum) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Amount sum";
    }
    if (criterion.hasCurrency) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Currency";
    }
    if (criterion.hasBeneficiaryName) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + beneficiaryCaption + " name";
    }
    if (criterion.hasBeneficiaryRegNo) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + beneficiaryCaption + " reg. No.";
    }
    if (criterion.beneficiaryId) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Specified " + beneficiaryCaption.toLowerCase();
    }
    if (criterion.hasRecordTypeIsBankCharge) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Record type is 'Bank charge'";
    }
    if (criterion.hasInvoiceNoFullyContainedInPaymentDetails) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Invoice No. (full match)";
    }
    if (criterion.hasInvoiceNoAltFullyContainedInPaymentDetails) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Alt. invoice No. (full match)";
    }
    if (criterion.hasInvoiceNoAltNumericPartInPaymentDetails) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Alt. invoice No. (numeric part)";
    }
    if (criterion.hasPaymentDetailsFullyMatch) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Payment details (full match)";
    }
    if (criterion.paymentDetailsTemplate && criterion.paymentDetailsTemplate.length) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Payment details matches template";
    }
    if (criterion.hasPaymentDateGreaterThanInvoiceDate) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Payment date > Invoice date";
    }
    if (criterion.hasPaymentDateGreaterThanOrEqualsInvoiceDate) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Payment date >= Invoice date";
    }
    if (criterion.hasPaymentDateEqualsInvoiceDate) {
        criterionDescriptionText += (criterionDescriptionText.length ? ", " : "") + "Payment date = Invoice date";
    }
    return criterionDescriptionText;
}

export const formatAllocationCriterion = (criterion, rowIndex) => {
    let trustLevelComponent = null;
    let sequenceNoComponent = null;
    let criterionTypeComponent = null;
    let criterionDescriptionText = "This record has been allocated manually.";
    if (criterion && criterion.trustLevel) {
        const trustLevelText = formatAllocationCriterionTrustLevelText(criterion.trustLevel);
        criterionDescriptionText = formatAllocationCriterionDescriptionText(criterion);
        trustLevelComponent = (
            <React.Fragment>
                <br />
                Trust level: {trustLevelText}
            </React.Fragment>
        )
        sequenceNoComponent = (
            <React.Fragment>
                <br />
                Sequence No.: {criterion.isActive ? criterion.sequenceNo : "(disabled)"}
            </React.Fragment>
        )
        criterionTypeComponent = (
            <React.Fragment>
                <br />
                Criterion type: {AllocationCriterionType.AllocationCriterionType.filter(type => type.id === criterion.type)[0].description}
            </React.Fragment>
        )
    }
    const trustLevelBadge = formatAllocationCriterionTrustLevel(criterion && criterion.trustLevel ? criterion.trustLevel : "MANUAL");
    const popoverId = "criterionDescriptionPopover" + (rowIndex ? rowIndex : "");
    return (
        <div>
            {trustLevelBadge}
            &nbsp;
            <i
                id={popoverId}
                data-target={popoverId}
                className={"bx bx-info-circle link-secondary"}
            />

            <UncontrolledPopover
                placement="right"
                target={popoverId}
                trigger="hover"
            >
                <PopoverHeader>
                    Allocation criterion
                </PopoverHeader>
                <PopoverBody>
                    {criterionDescriptionText}
                    {trustLevelComponent}
                    {sequenceNoComponent}
                    {criterionTypeComponent}
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    )
}

export const formatPaymentStatus = (paymentStatus) => {
    if (paymentStatus) {
        const color = PaymentStatus.formatPaymentStatusColor(paymentStatus);
        const description = PaymentStatus.PaymentStatus.filter(row => row.id === paymentStatus)[0].description;
        return (
            <span
                className={"badge badge-soft-" + color + " font-size-12"}
                style={{ marginBottom: "0.3rem" }}
            >
                {description}
            </span>
        );
    } else {
        return "";
    }
}

export const hasOnlyDigits = (string) => {
    return string.replaceAll(/[0-9]/g, "").length == 0
}

export const formatIdentityNumber = (identityNumber, countryCode) => {
    // Returns NULL if the supplied identity number is not in the correct format
    switch (countryCode.trim().toUpperCase()) {
        case "LV":
            identityNumber = identityNumber.trim().replaceAll("-", "");
            if (identityNumber.length == 11 && hasOnlyDigits(identityNumber)) {
                identityNumber = identityNumber.substring(0, 6) + "-" + identityNumber.substring(6)
                return identityNumber;
            } else {
                return null;
            }
        case "EE":
        case "LT":
            identityNumber = identityNumber.trim().replaceAll("-", "");
            if (identityNumber.length == 11 && hasOnlyDigits(identityNumber)) {
                return identityNumber;
            } else {
                return null;
            }
        default:
            return identityNumber.trim();
    }
}

export const formatTag = (tag) => {
    const tagTitleSimplified = tag.title && tag.title.includes("/") ? tag.title.substring(tag.title.lastIndexOf("/") + 1) : tag.title;

    return (
        <React.Fragment>
            <span
                className={"badge badge-soft-secondary font-size-12"}
            >
                {tagTitleSimplified}
            </span>
            <span>
                &nbsp;
            </span>
        </React.Fragment>
    );
}

export const formatTags = (tags) => {
    if (tags && tags.length) {
        return (
            <div>
                {tags.map(tag => {
                    return (
                        <span
                            key={tag.id}
                        >
                            {formatTag(tag)}
                        </span>
                    );
                })}
            </div>
        )
    } else {
        return "";
    }
}

export const detectDecimalSeparator = () => {
    const number = 1.1;
    const formattedNumber = number.toLocaleString();
    return formattedNumber.replace(/\d/g, '');
}

export const formatScheduledTaskStatus = (status) => {
    if (status) {
        const color = ScheduledTaskStatus.formatScheduledTaskStatusColor(status);
        const filteredRows = ScheduledTaskStatus.ScheduledTaskStatus.filter(row => row.id === status);
        const description = filteredRows.length ? filteredRows[0].description : ""
        return (
            <span
                className={"badge badge-soft-" + color + " font-size-12"}
                style={{ marginBottom: "0.3rem" }}
            >
                {description}
            </span>
        );
    } else {
        return "";
    }
}

export const formatScheduledTaskType = (cell, row) => {
    let taskTypeDescription = cell ? ScheduledTaskType.ScheduledTaskType.filter(row => row.id === cell)[0].description : "";
    let budgetingOnlyBadge = null;
    if (row && row.forBudgetingOnly) {
        budgetingOnlyBadge =
            <span
                className={"badge badge-soft-secondary font-size-12"}
                style={{ marginBottom: "0.3rem" }}
            >
                Budgeting only
            </span>
    }
    return (
        <React.Fragment>
            <span>{taskTypeDescription}</span>&nbsp;&nbsp;{budgetingOnlyBadge}
        </React.Fragment>
    )
}

export const companyNameAsFilenamePrefix = (selectedCompany) => {
    const selectedCompanyName = (selectedCompany && selectedCompany.name ? selectedCompany.name + "" : "");
    return selectedCompanyName.length ? selectedCompanyName.replaceAll(/[^a-zA-Z0-9]/g, "-").toLowerCase() + "-" : "";
}

export const formatCounterType = (cell) => {
    const filteredCounterType = CounterType.CounterType.filter(row => row.id === cell);
    return filteredCounterType && filteredCounterType.length ? filteredCounterType[0].description : "";
}

export const formatMatchingStatus = (status, warningOnDataNotSpecified) => {
    if (status) {
        const color = MatchingStatus.formatMatchingStatusColor(status, warningOnDataNotSpecified);
        const filteredRows = MatchingStatus.MatchingStatus.filter(row => row.id === status);
        const description = filteredRows.length ? filteredRows[0].description : ""
        return (
            <span
                className={"badge badge-soft-" + color + " font-size-12"}
                style={{ marginBottom: "0.3rem" }}
            >
                {description}
            </span>
        );
    } else {
        return "";
    }
}

export const formatInvoiceDuplicationStatus = (status) => {
    if (status) {
        const color = InvoiceDuplicationStatus.formatInvoiceDuplicationStatusColor(status);
        const filteredRows = InvoiceDuplicationStatus.InvoiceDuplicationStatus.filter(row => row.id === status);
        const description = filteredRows.length ? filteredRows[0].description : ""
        return (
            <span
                className={"badge badge-soft-" + color + " font-size-12"}
                style={{ marginBottom: "0.3rem" }}
            >
                {description}
            </span>
        );
    } else {
        return "";
    }
}

export const formatSupplierInvoiceCandidateStatus = (status) => {
    if (status) {
        const color = SupplierInvoiceCandidateStatus.formatSupplierInvoiceCandidateStatusColor(status);
        const filteredRows = SupplierInvoiceCandidateStatus.SupplierInvoiceCandidateStatus.filter(row => row.id === status);
        const description = filteredRows.length ? filteredRows[0].description : ""
        return (
            <span
                className={"badge badge-soft-" + color + " font-size-12"}
                style={{ marginBottom: "0.3rem" }}
            >
                {description}
            </span>
        );
    } else {
        return "";
    }
}

export const formatSupplierBankAccountStatus = (status) => {
    if (status) {
        const color = SupplierBankAccountStatus.formatSupplierBankAccountStatusColor(status);
        const filteredRows = SupplierBankAccountStatus.SupplierBankAccountStatus.filter(row => row.id === status);
        const description = filteredRows.length ? filteredRows[0].description : ""
        return (
            <span
                className={"badge badge-soft-" + color + " font-size-12"}
                style={{ marginBottom: "0.3rem" }}
            >
                {description}
            </span>
        );
    } else {
        return "";
    }
}