import React, { Component } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Label, Input, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from "react-redux";

import * as actions from 'store/Supplier/actions';
import Backdrop from '../Common/Backdrop';
import * as columnsSupplier from 'definitions/columns/supplier';
import * as editFormControls from 'helpers/editFormControls';

import classes from './Pages.module.css';

class SelectSupplier extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRow: null,
            advanceNo: "",
            externalAccountType: 1,

            advanceNoIsEmpty: false,
            advanceNoAlreadyUsed: false
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    componentDidMount() {
        this.props.onGetSuppliers();
        const fieldsFromProps = this.props.onGetFields();
        this.setState({
            supplierName: fieldsFromProps.supplierName,
            supplierRegNo: fieldsFromProps.supplierRegNo,
            supplierVATRegNo: fieldsFromProps.supplierVATRegNo
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onSelectSupplier = () => {
        if (this.state.selectedRow) {
            this.props.onSelectSupplier(this.state.selectedRow);
            this.props.onClose();
        } 
    }

    render() {

        const loading = this.props.loading;

        const supplierDataForm =
            <React.Fragment>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryName"
                        className="col-form-label col-lg-2"
                    >
                        Name on the invoice
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryName"
                            name="beneficiaryName"
                            type="text"
                            className="form-control-plaintext"
                            value={this.state.supplierName}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.suppliers}
                                onClick={() => this.supplierNameFilter(this.state.supplierName)}
                            >
                                Filter by this name
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryRegNo"
                        className="col-form-label col-lg-2"
                    >
                        Reg. No. on the invoice
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryRegNo"
                            name="beneficiaryRegNo"
                            type="text"
                            className="form-control-plaintext"
                            value={this.state.supplierRegNo}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.suppliers}
                                onClick={() => this.supplierRegNoFilter(this.state.supplierRegNo)}
                            >
                                Filter by this reg. No.
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryVATRegNo"
                        className="col-form-label col-lg-2"
                    >
                        VAT reg. No. on the invoice
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryVATRegNo"
                            name="beneficiaryVATRegNo"
                            type="text"
                            className="form-control-plaintext"
                            value={this.state.supplierVATRegNo}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.suppliers}
                                onClick={() => this.supplierVATRegNoFilter(this.state.supplierVATRegNo)}
                            >
                                Filter by this VAT reg. No.
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
            </React.Fragment>

        const columns = [
            {
                ...columnsSupplier.name,
                filter: textFilter({
                    getFilter: (filter) => { this.supplierNameFilter = filter; }
                }),
            },
            {
                ...columnsSupplier.regNo,
                filter: textFilter({
                    getFilter: (filter) => { this.supplierRegNoFilter = filter; }
                }),
            },
            {
                ...columnsSupplier.vatRegNo,
                filter: textFilter({
                    getFilter: (filter) => { this.supplierVATRegNoFilter = filter; }
                }),
            }
        ];

        const selectRow = {
            mode: 'radio',

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({
                    selectedRow: row.id
                });
            },
        };

        const table =
            <div className="mt-3">
                <BootstrapTable
                    keyField='id'
                    data={this.props.suppliers}
                    columns={columns}
                    pagination={paginationFactory({ sizePerPageList: [10, 25] })}
                    filter={filterFactory()}
                    selectRow={selectRow}
                    condensed
                />
            </div>

        const advanceNoInput =
            <Col xs="auto">
                {editFormControls.textControlWithoutLabel("advanceNo", this.onChange, this.state.advanceNo, "Advance No.", this.state.advanceNoIsEmpty || this.state.advanceNoAlreadyUsed)}
            </Col>

        // const externalAccountInput =
        //     <React.Fragment>
        //         <Col xs="auto">
        //             <Input
        //                 name="externalAccountType"
        //                 type="radio"
        //                 onChange={this.onChange}
        //                 checked={this.state.externalAccountType == 1}
        //                 value={1}
        //             />
        //             &nbsp;
        //             Standard account
        //         </Col>
        //         <Col xs="auto">
        //             <Input
        //                 name="externalAccountType"
        //                 type="radio"
        //                 onChange={this.onChange}
        //                 checked={this.state.externalAccountType == 2}
        //                 value={2}
        //             />
        //             &nbsp;
        //             Prepayment account
        //         </Col>
        //     </React.Fragment>

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.props.onClose}
                >
                </Backdrop>
                <div
                    className={classes.PageCardContainer}
                >
                    <Card
                        // className={classes.PageCard} // Like in ColumnSelectionForm, for some reason specifying these parameters in CSS does not work 
                        style={{
                            height: "100%",
                            overflowX: "auto",
                            overflowY: "auto"
                        }}
                    >
                        {this.state.advanceNoAlreadyUsed && editFormControls.warningAlertWithLink("Advance No. " + this.state.advanceNo + " has already been used in this statement")}
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    Select a supplier
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.props.onClose}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>
                            {supplierDataForm}

                            {loading ? (
                                <div width="100%" height="100%" align="center">
                                    {editFormControls.bigSpinner()}
                                    <br /><br />
                                </div>
                            ) : null}

                            {!loading && this.props.suppliers ? table : null}

                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Button
                                            color="primary"
                                            onClick={this.onSelectSupplier}
                                            disabled={!this.state.selectedRow}
                                        >
                                            Select
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                            <br />
                            <br />
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ supplier }) => ({
    error: supplier.error,
    loading: supplier.loading,
    loadingTotals: supplier.loadingTotals,
    suppliers: supplier.suppliers,
    totals: supplier.totals,
});

const mapDispatchToProps = dispatch => ({
    onGetSuppliers: () => dispatch(actions.supplierGetAll())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectSupplier);